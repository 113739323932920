import i18n from 'i18next';
import { round, isValidNum, toNum } from '@oliasoft-open-source/units';
import { Text, HelpIcon, Spacer } from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import { validateString } from '~src/validation/common/validate-string';
import { costTrackerFields } from '~src/enums/cost-tracker';
import { CellWithExpandingIcon } from '~views/time-tracker/cell-with-expanding-icon';
import { formatTimeToUI } from '~views/time-tracker/utils/date/dateUtils';
import {
  CostTrackerItem,
  GroupedCostTrackerItem,
} from '~src/common/interfaces/cost-tracker.interfaces';
import { APIActionPayload } from '~api/api';
import { DebouncedFunc } from 'lodash';
import { ChangeEvent, MutableRefObject } from 'react';
import { thousandsSeparators, getSuggestedValue, removeSpaceSeparators } from './utils/utils';
import { getFields } from './utils/get-ct-fields';

type TCellValueUpdate = {
  sectionId: string | null;
  costTrackerItemId: string;
  value: string | number | null;
  field: string;
};

type TBildCostTrackerItem = {
  data: CostTrackerItem | GroupedCostTrackerItem;
  disabledFields: string[];
  updateTask?: MutableRefObject<
    DebouncedFunc<
      (
        costTrackerItemId: string,
        data: { field: string; value: string | number | null },
      ) => { payload: APIActionPayload; type: string }
    >
  >;
  cellValueUpdated?: (arg: TCellValueUpdate) => void;
  isExpanded?: boolean;
  isOperation?: boolean;
  costsArray?: any[];
};

export const buildCostTrackerItem = ({
  data,
  disabledFields,
  updateTask,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  cellValueUpdated = (_arg: TCellValueUpdate) => null,
  isExpanded = true,
  isOperation = true,
  costsArray = [],
}: TBildCostTrackerItem) => {
  const rowStyle = {
    style: {
      backgroundColor: isOperation
        ? ''
        : 'var(--color-background-table-header)',
    },
  };
  return getFields(disabledFields).map((fieldName, idx) => {
    switch (fieldName) {
      case costTrackerFields.START:
        return {
          ...rowStyle,
          value: formatTimeToUI(data[fieldName]),
          type: 'Input',
          disabled: true,
        };
      case costTrackerFields.COST_AFE:
        return {
          ...rowStyle,
          value: thousandsSeparators(round(data[fieldName], 0)),
          type: 'Input',
          disabled: true,
        };
      case costTrackerFields.TIME_AFE:
      case costTrackerFields.TIME_ACTUAL:
        return {
          ...rowStyle,

          value: round(data[fieldName], 2),
          type: 'Input',
          disabled: true,
        };
      case costTrackerFields.VARIANCE: {
        const variance =
          data.costActual &&
          data.costAfe &&
          isValidNum(data.costActual) &&
          isValidNum(data.costAfe)
            ? round(data.costActual - data.costAfe, 0)
            : null;
        const style = {
          color:
            variance && variance > 0
              ? 'var(--color-text-error)'
              : 'var(--color-text-success)',
        };
        return {
          style: {
            backgroundColor: isOperation
              ? 'var(--color-background-disabled)'
              : '#d6d6d0',
          },
          value: <span style={style}>{thousandsSeparators(variance)}</span>,
          disabled: true,
        };
      }
      case costTrackerFields.CUMULATIVE_COST: {
        return {
          ...rowStyle,
          value: thousandsSeparators(data[fieldName]),
          type: 'Input',
          disabled: true,
        };
      }
      case costTrackerFields.NAME: {
        if (!isOperation) {
          return {
            ...rowStyle,
            value: (
              <CellWithExpandingIcon
                cellContent={data[fieldName]}
                index={idx}
                isExpanded={!isExpanded}
              />
            ),
          };
        }
        return {
          ...rowStyle,
          value: data[fieldName],
          type: 'Input',
          error: i18n.t(validateString(data[fieldName] || '')),
          onChange: (e: ChangeEvent<HTMLInputElement>): void => {
            if (isOperation) {
              cellValueUpdated({
                sectionId: data.sectionId!,
                costTrackerItemId: (data as CostTrackerItem).costTrackerItemId,
                value: e.target.value,
                field: fieldName,
              });
              if (!validateString(data[fieldName] || '') && updateTask) {
                updateTask?.current(
                  (data as CostTrackerItem).costTrackerItemId,
                  {
                    value: e.target.value,
                    field: fieldName,
                  },
                );
              }
            }
          },
          disabled: !isOperation,
        };
      }
      default: {
        return {
          ...rowStyle,
          value: thousandsSeparators(data[fieldName]) ?? '',
          type: 'Input',
          allowEmpty: true,
          left: false,
          disabled: !isOperation,
          onBlur: (e: ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target;
            if (!value && data[costTrackerFields.TIME_ACTUAL]) {
              const sugestedValue = getSuggestedValue(
                costsArray,
                (data as CostTrackerItem).originId,
                data[costTrackerFields.TIME_ACTUAL],
              );
              cellValueUpdated({
                sectionId: data.sectionId,
                costTrackerItemId: (data as CostTrackerItem).costTrackerItemId,
                value: sugestedValue,
                field: fieldName,
              });
              if (updateTask) {
                updateTask.current((data as CostTrackerItem).costTrackerItemId, {
                value: value === '' ? null : value,
                field: fieldName,
              });
              }
            }
          },
          onChange: (e: ChangeEvent<HTMLInputElement>): void => {
            const { value } = e.target;
            cellValueUpdated({
              sectionId: data.sectionId,
              costTrackerItemId: (data as CostTrackerItem).costTrackerItemId,
              value: isValidNum(value) && value !== '' ? toNum(value) : null,
              field: fieldName,
            });
            if (updateTask) {
              updateTask.current((data as CostTrackerItem).costTrackerItemId, {
              value: value === '' ? null : removeSpaceSeparators(value),
              field: fieldName,
            });
            }
          },
        };
      }
    }
  });
};

export const buildHeaders = (
  disabledFields: string[],
  labelCofig: { [key: string]: string } = {},
) => {
  const cellsMap = [
    {
      columnName: costTrackerFields.NAME,
      value: 'Section/Operation',
      unit: '',
    },
    {
      columnName: costTrackerFields.START,
      value: i18n.t(translations.timeTracker_startTime),
      unit: '',
    },
    {
      columnName: costTrackerFields.TIME_AFE,
      value:
        labelCofig[costTrackerFields.TIME_AFE] ||
        i18n.t(translations.costTracker_timeAfe),
      unit: 'h',
    },
    {
      columnName: costTrackerFields.COST_AFE,
      value: i18n.t(translations.costTracker_costAfe),
      unit: 'USD',
    },
    {
      columnName: costTrackerFields.TIME_ACTUAL,
      value: (
        <>
          <Text>{i18n.t(translations.costTracker_timeActual) as string}</Text>
          <Spacer width={4} />
          <HelpIcon
            icon="help"
            text={i18n.t(translations.costTracker_timeActualTooltip) as string}
          />
        </>
      ),
      unit: 'h',
    },
    {
      columnName: costTrackerFields.COST_ACTUAL,
      value: i18n.t(translations.costTracker_costActual),
      unit: 'USD',
    },
    {
      columnName: costTrackerFields.VARIANCE,
      value: i18n.t(translations.costTracker_variance),
      unit: 'USD',
    },
    {
      columnName: costTrackerFields.CUMULATIVE_COST,
      value: i18n.t(translations.costTracker_cumulativeCost),
      unit: 'USD',
    },
  ];

  const shownFields = cellsMap.filter(
    (item) =>
      !disabledFields.some(
        (disabledField) => disabledField === item.columnName,
      ),
  );
  const labelsRow = shownFields.map(({ columnName, value }) => ({
    columnName,
    value,
  }));
  const unitRow = shownFields.map(({ unit }) => ({
    value: unit,
    type: 'Unit',
  }));

  return [{ cells: labelsRow }, { cells: unitRow }];
};
