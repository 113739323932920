import { useEffect, useMemo, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import {
  Button,
  Column,
  Divider,
  Heading,
  Page,
  Row,
} from '@oliasoft-open-source/react-ui-library';
import {
  HierarchyModals,
  IHierarchyModalsRef,
  IUnitTemplate,
  TTranslations,
  preparedTranslations,
} from '@oliasoft/hierarchy-gui';
import { setCurrentPage } from '~src/store/entities/navigation/navigation';
import { DeleteModal } from '~views/hierarchy/delete.modal';
import { CountriesList } from '~views/hierarchy/countries/countries.list';
import { FieldsList } from '~views/hierarchy/fields/fields.list';
import { SitesList } from '~views/hierarchy/sites/sites.list';
import { WellsList } from '~views/hierarchy/wells/wells.list';
import { WellboresList } from '~views/hierarchy/wellbores/wellbores.list';
import { DesignsList } from '~views/hierarchy/designs/designs.list';
import { DesignModal } from '~views/hierarchy/designs/design.modal';
import { EstimateList } from '~views/hierarchy/estimates/estimates.list';
import { EstimateModal } from '~views/hierarchy/estimates/estimate.modal';
import { CreateModal } from '~views/hierarchy/create.modal';
import { TItemIdField } from '~src/common/interfaces/hierarchy.interfaces.ts';
import translations from '~src/internationalisation/translation-map.json';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import { updateProjectCategory } from '~src/store/entities/project-settings/project-settings';
import {
  getCountriesList,
  hierarchyModalSettings,
  itemModalVisibleUpdated,
  createHierarchyItem,
  updateHierarchyItem,
  getHierarchyItemChildren,
  itemDeleteRequested,
  getItems,
} from '~store/entities/hierarchy/hierarchy';
import { overviewRequested } from '~store/entities/projects/projects';
import { HierarchyLevelType, IdFieldMap } from '~src/enums/hierarchy';
import type { TRootState } from '~src/store/store-types';

const Hierarchy = ({
  setCurrentPage,
  getCountriesList,
  activeCountry,
  activeField,
  activeSite,
  activeWell,
  activeWellbore,
  activeDesign,
  countriesList,
  fieldsList,
  sitesList,
  wellsList,
  wellboresList,
  designsList,
  estimatesList,
  isCountryFetching,
  isFieldFetching,
  isSiteFetching,
  isWellFetching,
  isWellboreFetching,
  isDesignFetching,
  isEstimateFetching,
  isAdding,
  countriesOptions,
  updateProjectCategory,
  itemModalVisibleUpdated,
  designModalVisible,
  estimateModalVisible,
  deleteModalVisible,
  createHierarchyItem,
  updateHierarchyItem,
  itemDeleteRequested,
  getItems,
  overviewRequested
}: PropsFromRedux) => {
  const childRef = useRef<IHierarchyModalsRef>(null);
  const { t } = useTranslation();
  const { company: companyId } = useParams();
  const [editCustomItem, setEditCustomItem] = useState<any>(null);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const columnWidth = '14.3%';

  const selectedProjectFromLocalStorage =
    localStorage.getItem('selectedProject');
  const selectedProject = selectedProjectFromLocalStorage
    ? JSON.parse(selectedProjectFromLocalStorage)
    : {};

  const hierarchy = useMemo(
    () => ({
      countries: countriesList,
      fields: fieldsList,
      sites: sitesList,
      wells: wellsList,
      wellbores: wellboresList,
    }),
    [countriesList, fieldsList, sitesList, wellsList, wellboresList],
  );

  useEffect(() => {
    getCountriesList();
  }, []);

  useEffect(() => {
    setCurrentPage('projects');
  }, [setCurrentPage]);

  const onClickDelete = (id: string, type: HierarchyLevelType) => {
    itemDeleteRequested({ id, type });
    overviewRequested();
  };

  const onUpdateProjectCategory = async (id: string, category: string) => {
    updateProjectCategory(id, category);
    if (activeDesign?.designid) {
      getItems(
        companyId as string,
        HierarchyLevelType.Estimate,
        activeDesign.designid,
      );
    }
  };

  const createItem = (entityType: HierarchyLevelType) => {
    switch (entityType) {
      case HierarchyLevelType.Country: {
        childRef.current?.countryAdditionStarted({ countryid: uuidv4() });
        break;
      }
      case HierarchyLevelType.Field: {
        if (activeCountry) {
          childRef.current?.fieldAdditionStarted({
            country: activeCountry,
            fieldid: uuidv4(),
          });
        }
        break;
      }
      case HierarchyLevelType.Site: {
        if (activeField) {
          childRef.current?.siteAdditionStarted({
            field: activeField,
            siteid: uuidv4(),
          });
        }
        break;
      }
      case HierarchyLevelType.Well: {
        if (activeSite) {
          childRef.current?.wellAdditionStarted({
            site: activeSite,
            wellid: uuidv4(),
          });
        }
        break;
      }
      case HierarchyLevelType.Wellbore: {
        if (activeWell) {
          childRef.current?.wellBoreAdditionStarted({
            wellid: activeWell.wellid,
            wellboreid: uuidv4(),
          });
        }
        break;
      }
      default:
        break;
    }
  };

  const editItem = (item: any, entityType: HierarchyLevelType) => {
    switch (entityType) {
      case HierarchyLevelType.Country: {
        childRef.current?.countryConfigurationStarted(item);
        break;
      }
      case HierarchyLevelType.Field: {
        childRef.current?.fieldConfigurationStarted(item);
        break;
      }
      case HierarchyLevelType.Site: {
        childRef.current?.siteConfigurationStarted(item);
        break;
      }
      case HierarchyLevelType.Well: {
        childRef.current?.wellConfigurationStarted(item);
        break;
      }
      case HierarchyLevelType.Wellbore: {
        childRef.current?.wellBoreConfigurationStarted(item);
        break;
      }
      default:
        break;
    }
  };

  const onItemAdded = (entity: { type: string; payload: any }): any => {
    createHierarchyItem(
      companyId as string,
      entity.type as HierarchyLevelType,
      entity.payload,
    );
  };
  const onItemEdited = (entity: { type: string; payload: any }): any => {
    updateHierarchyItem(
      companyId as string,
      entity.type as HierarchyLevelType,
      entity.payload[
        IdFieldMap.get(entity.type as HierarchyLevelType) as TItemIdField
      ],
      entity.payload,
    );
  };

  const getAffectedSitesAndWells = async (itemId: string) => {
    if (!itemId) {
      return {
        wells: [],
        sites: [],
      };
    }

    const affectedItems = await getHierarchyItemChildren(
      companyId as string,
      HierarchyLevelType.Field,
      itemId,
    );

    return {
      sites: affectedItems?.sites || [],
      wells: affectedItems?.wells || [],
    };
  };

  const selection = {
    selectedCountry: activeCountry?.countryid as string,
    selectedField: activeField?.fieldid as string,
    selectedSite: activeSite?.siteid as string,
    selectedWell: activeWell?.wellid as string,
    selectedWellBore: activeWellbore?.wellboreid as string,
  };

  return (
    <>
      <Page left={0} padding={false} scroll={false}>
        <HierarchyModals
          ref={childRef}
          // settings microservice data
          settings={hierarchyModalSettings}
          hierarchy={hierarchy}
          // currently active items for project specific
          selection={selection}
          isCountryLoading={isCountryFetching}
          isFieldLoading={isFieldFetching}
          isSiteLoading={isSiteFetching}
          isWellLoading={isWellFetching}
          isWellBoreLoading={isWellboreFetching}
          translations={preparedTranslations(t, translations) as TTranslations}
          handleHierarchyItemAdded={onItemAdded}
          handleHierarchyItemEdited={onItemEdited}
          getAffectedSitesAndWells={getAffectedSitesAndWells}
          unitTemplate={{} as IUnitTemplate}
          SideTrackSection={() => null}
          lockers={{
            isCountryLocationLocked: false,
            isFieldNameLocked: false,
            isFieldLocationLocked: false,
            isSlotLocked: false,
            isSiteNameLocked: false,
            isTemplateLocked: false,
            isSiteUncertaintyLocked: false,
            isCoordinateLocked: false,
            isApplyScaleLocked: false,
            isNorthReferenceLocked: false,
            isWellNameLocked: false,
            isWellCoordinateLocked: false,
            isWellTypeLocked: false,
            isWellUncertaintyLocked: false,
            isUwiInputDisabled: false,
            isWellBoreNameLocked: false,
            isUwiWellboreInputDisabled: false,
          }}
          reservoirFeatureFlagEnabled={false}
          ReservoirModelsComponent={() => null}
          DepthDiagramRender={() => null}
        />

        <Row justifyContent="space-between" alignItems="center">
          <Column flex={false} padding="var(--padding)">
            <Heading top marginBottom={0} testId="estimate-label">
              {t(translations.estimates)}
            </Heading>
          </Column>
          <Column flex={false} padding="var(--padding)">
            <Button
              name="create-estimate"
              label={t(translations.projects_createEstimate)}
              onClick={() => {
                setCreateModalVisible(true);
              }}
              colored
            />
          </Column>
        </Row>
        <Divider margin={0} />
        <Row spacing={0} flex>
          <Column scroll width={columnWidth} borderRight>
            <CountriesList
              companyId={companyId as string}
              isCountryFetching={isCountryFetching}
              onClickDelete={onClickDelete}
              countries={countriesList}
              createItem={() => createItem(HierarchyLevelType.Country)}
              editItem={editItem}
              selectedProject={selectedProject}
            />
          </Column>
          <Column scroll width={columnWidth} borderRight>
            <FieldsList
              companyId={companyId as string}
              isFieldFetching={isFieldFetching}
              onClickDelete={onClickDelete}
              fields={fieldsList}
              addButtonDisabled={countriesList.length === 0 || !activeCountry}
              activeCountry={activeCountry}
              createItem={() => createItem(HierarchyLevelType.Field)}
              editItem={editItem}
              selectedProject={selectedProject}
            />
          </Column>
          <Column scroll width={columnWidth} borderRight>
            <SitesList
              companyId={companyId as string}
              isSiteFetching={isSiteFetching}
              onClickDelete={onClickDelete}
              sites={sitesList}
              addButtonDisabled={fieldsList.length === 0 || !activeField}
              activeField={activeField}
              createItem={() => createItem(HierarchyLevelType.Site)}
              editItem={editItem}
              selectedProject={selectedProject}
            />
          </Column>
          <Column scroll width={columnWidth} borderRight>
            <WellsList
              companyId={companyId as string}
              isWellFetching={isWellFetching}
              onClickDelete={onClickDelete}
              wells={wellsList}
              addButtonDisabled={sitesList.length === 0 || !activeSite}
              activeSite={activeSite}
              createItem={() => createItem(HierarchyLevelType.Well)}
              editItem={editItem}
              selectedProject={selectedProject}
            />
          </Column>
          <Column scroll width={columnWidth} borderRight>
            <WellboresList
              companyId={companyId as string}
              isWellboreFetching={isWellboreFetching}
              onClickDelete={onClickDelete}
              wellbores={wellboresList}
              addButtonDisabled={wellsList.length === 0 || !activeWell}
              activeWell={activeWell}
              createItem={() => createItem(HierarchyLevelType.Wellbore)}
              editItem={editItem}
              selectedProject={selectedProject}
            />
          </Column>
          <Column scroll width={columnWidth} borderRight>
            <DesignsList
              companyId={companyId as string}
              isDesignFetching={isDesignFetching}
              onClickDelete={onClickDelete}
              itemModalVisibleUpdated={itemModalVisibleUpdated}
              setEditItem={setEditCustomItem}
              designs={designsList}
              addButtonDisabled={wellboresList.length === 0 || !activeWellbore}
              activeWellbore={activeWellbore}
              isAdding={isAdding}
              selectedProject={selectedProject}
            />
          </Column>
          <Column scroll width={columnWidth}>
            <EstimateList
              companyId={companyId as string}
              isEstimateFetching={isEstimateFetching}
              onClickDelete={onClickDelete}
              itemModalVisibleUpdated={itemModalVisibleUpdated}
              setEditItem={setEditCustomItem}
              estimates={estimatesList}
              addButtonDisabled={designsList.length === 0 || !activeDesign}
              onUpdateProjectCategory={onUpdateProjectCategory}
              activeDesign={activeDesign}
              selectedProject={selectedProject}
            />
          </Column>
        </Row>
      </Page>
      {designModalVisible && (
        <DesignModal
          companyId={companyId as string}
          itemModalVisibleUpdated={itemModalVisibleUpdated}
          activeWellbore={activeWellbore}
          editItem={editCustomItem}
          setEditItem={setEditCustomItem}
          isAdding={isAdding}
        />
      )}
      {estimateModalVisible && (
        <EstimateModal
          companyId={companyId as string}
          itemModalVisibleUpdated={itemModalVisibleUpdated}
          estimates={estimatesList}
          activeDesign={activeDesign}
          editItem={editCustomItem}
          setEditItem={setEditCustomItem}
          isAdding={isAdding}
        />
      )}
      {deleteModalVisible && <DeleteModal companyId={companyId as string} />}
      {createModalVisible && (
        <CreateModal
          companyId={companyId as string}
          setModalVisible={setCreateModalVisible}
          countriesOptions={countriesOptions}
          activeCountry={activeCountry}
          activeField={activeField}
          activeSite={activeSite}
          activeWell={activeWell}
          activeWellbore={activeWellbore}
          activeDesign={activeDesign}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ entities }: TRootState) => {
  const {
    list,
    countriesList: allCountriesList,
    designModalVisible,
    estimateModalVisible,
    deleteModalVisible,
    isAdding,
  } = entities.hierarchy;
  const {
    country: { data: countriesList, isFetching: isCountryFetching },
    field: { data: fieldsList, isFetching: isFieldFetching },
    site: { data: sitesList, isFetching: isSiteFetching },
    well: { data: wellsList, isFetching: isWellFetching },
    wellbore: { data: wellboresList, isFetching: isWellboreFetching },
    design: { data: designsList, isFetching: isDesignFetching },
    estimate: { data: estimatesList, isFetching: isEstimateFetching },
  } = list;
  const activeCountry = countriesList.find((country) => country.active) || null;
  const activeField = fieldsList.find((field) => field.active) || null;
  const activeSite = sitesList.find((site) => site.active) || null;
  const activeWell = wellsList.find((well) => well.active) || null;
  const activeWellbore =
    wellboresList.find((wellbore) => wellbore.active) || null;
  const activeDesign = designsList.find((design) => design.active) || null;
  const existingCountries = countriesList.map((country) => country.name);

  const countriesOptions = allCountriesList
    .filter(
      (country: { name: string; code: string }) =>
        !existingCountries.includes(country.name),
    )
    .map((country: { name: string; code: string }) => ({
      label: country.name,
      value: country.code,
    }));

  return {
    isAdding,
    activeCountry,
    activeField,
    activeSite,
    activeWell,
    activeWellbore,
    activeDesign,
    countriesList,
    fieldsList,
    sitesList,
    wellsList,
    wellboresList,
    designsList,
    estimatesList,
    isCountryFetching,
    isFieldFetching,
    isSiteFetching,
    isWellFetching,
    isWellboreFetching,
    isDesignFetching,
    isEstimateFetching,
    countriesOptions,
    showCompareModal: entities.compareEstimates.showCompareModal,
    designModalVisible,
    estimateModalVisible,
    deleteModalVisible,
  };
};

const mapDispatchToProps = {
  setCurrentPage,
  getCountriesList,
  updateProjectCategory,
  itemModalVisibleUpdated,
  createHierarchyItem,
  updateHierarchyItem,
  itemDeleteRequested,
  getItems,
  overviewRequested
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Container = withErrorBoundary(connector(Hierarchy), {
  style: { position: 'absolute', top: '50px' },
});

export { Container as Hierarchy };
