import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@oliasoft/authentication/pkce';
import { Menu, Button, Icon } from '@oliasoft-open-source/react-ui-library';
import { routes } from '~views/navigation/routes/routes';
import translations from '~src/internationalisation/translation-map.json';
import { useParams } from 'react-router';

function UserMenu({ navigateToRoute, version, serverVersion }) {
  const { t } = useTranslation();
  const { company } = useParams();
  const [activeCompany, setActiveCompany] = useState(null);
  const auth = useAuth();
  const user = auth?.user;
  const profile = user?.profile;

  useEffect(() => {
    setActiveCompany(company);
    const eventHandler = () => {
      setActiveCompany(company);
    };
    window.addEventListener('storage', eventHandler);

    return () => window.removeEventListener('storage', eventHandler);
  }, []);

  const handleLogout = async () => {
    await auth.signoutRedirect({
      id_token_hint: user?.id_token,
      state: {
        last_url: window.location.pathname,
      },
    });
  };
  const initials = profile?.name
    ?.split(' ')
    ?.filter((v) => v)
    ?.map((v) => v[0].toUpperCase())
    ?.join('');

  return (
    <Menu
      menu={{
        trigger: 'Component',
        placement: 'bottom-end',
        fullHeightTrigger: true,
        component: <Button label={initials} round onClick={() => {}} colored />,
        sections: [
          { type: 'Heading', label: profile?.name },
          {
            type: 'Option',
            label: t(translations.settings_userSettings),
            icon: <Icon icon="user" />,
            onClick: () => navigateToRoute(routes.userSettings.nested.units),
          },
          ...(company || activeCompany
            ? [
                {
                  type: 'Option',
                  label: t(translations.settings_companySettings),
                  icon: <Icon icon="company" />,
                  onClick: () =>
                    navigateToRoute(routes.companySettings.nested.currency, {
                      company: company || activeCompany,
                    }),
                },
              ]
            : []),
          { type: 'Divider' },
          {
            type: 'Option',
            label: t(translations.signOut),
            icon: <Icon icon="lock" />,
            onClick: handleLogout,
          },
          { type: 'Divider' },
          version && {
            type: 'Option',
            disabled: true,
            label: `${t(translations.clientVersion)}: ${version}`,
          },
          {
            type: 'Option',
            disabled: true,
            label: `${t(translations.serverVersion)}: ${serverVersion}`,
          },
        ],
      }}
    />
  );
}

export default UserMenu;
