import { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { OptionDropdown, Icon } from '@oliasoft-open-source/react-ui-library';
import { disabledFieldsUpdated } from '~store/entities/cost-tracker/cost-tracker';
import { costTrackerFields } from '~src/enums/cost-tracker';
import translations from '~src/internationalisation/translation-map.json';

type TColumnOption = {
    label: string,
    value: costTrackerFields,
    selected: boolean,
}

type TColumnEditProps = {
  labelConfig?: {[key: string]: string},
} & PropsFromRedux;
const EditColumns = ({ disabledFieldsUpdated, labelConfig = {} }: TColumnEditProps) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<TColumnOption[]>([
    {
      label: t(translations.timeTracker_startTime),
      value: costTrackerFields.START,
      selected: true,
    },
    {
      label:
        labelConfig?.[costTrackerFields.TIME_AFE] ||
        t(translations.costTracker_timeAfe),
      value: costTrackerFields.TIME_AFE,
      selected: true,
    },
    {
      label:
        labelConfig?.[costTrackerFields.COST_AFE] ||
        t(translations.costTracker_costAfe),
      value: costTrackerFields.COST_AFE,
      selected: true,
    },
    {
      label: t(translations.costTracker_timeActual),
      value: costTrackerFields.TIME_ACTUAL,
      selected: true,
    },
    {
      label: t(translations.costTracker_costActual),
      value: costTrackerFields.COST_ACTUAL,
      selected: true,
    },
    {
      label: t(translations.timeTracker_variance),
      value: costTrackerFields.VARIANCE,
      selected: true,
    },
    {
      label: t(translations.costTracker_cumulativeCost),
      value: costTrackerFields.CUMULATIVE_COST,
      selected: true,
    },
  ]);

  const icon: any = <Icon icon="settings" size="16px" />;
  return (
    <OptionDropdown
      onChange={(e: any) => {
        setOptions(e.target.value);
        const disabledFields: string[] = e.target.value
          .filter((item: TColumnOption) => item.selected === false)
          .map((item: TColumnOption) => item.value);
        disabledFieldsUpdated(disabledFields);
      }}
      options={options}
      label={icon}
      showHeader={false}
    />
  );
};

const mapDispatchToProps = { disabledFieldsUpdated };

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Container = connector(EditColumns);

export { Container as EditColumns };
