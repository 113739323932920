import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { useForm } from 'react-hook-form';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import {
  Heading,
  Spacer,
  Field,
  Divider,
  FormRow,
} from '@oliasoft-open-source/react-ui-library';
import { LocationInput, ComponentRenderingType } from '@oliasoft/gis-ui';
import { DEFAULT_SITE_LOCATION } from '@oliasoft/gis-core';
import { getResolver } from '~src/validation/resolver';
import translations from '~src/internationalisation/translation-map.json';
import { Select, UnitInput } from '~common/form-inputs';
import { convertInputUnits } from '~common/units/units';
import { useAutoSave } from '~common/auto-save/use-auto-save';
import { autoSaveWait } from '~src/config/config';
import { wellDetailsSchemaValidator } from '~schemas/ajv-validators';
import {
  wellConfigurations,
  drillingUnits,
} from '~store/entities/user-settings/user-settings';
import {
  drillingUnit,
  wellConfiguration,
  InitDrillingUnitType,
  TLP_HYBRID,
  PLATFORM_JACKUP,
  SEMI_SUBMERSIBLE,
} from '~src/enums/settings';
import {
  initialWellDetails,
  saveWellDetails,
} from '~store/entities/well-details/well-details';
import { WellSchematicTable } from '~views/well-details/well-schematic-table';
import { AdditionalSectionsTable } from '~views/well-details/additional-sections.table';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const DetailsForm = ({
  well,
  drillingUnitOptions,
  wellConfigurationOptions,
  saveWellDetails,
  onClickDelete,
  isPageDisabled,
  unitSettings,
  sections,
  wellEvents,
  additionalSections,
}) => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
    trigger,
  } = useForm({
    mode: 'all',
    defaultValues: well || initialWellDetails,
    resolver: getResolver(wellDetailsSchemaValidator),
  });

  useEffect(() => {
    const wellData = well || initialWellDetails;
    const convertedWell = convertInputUnits(
      wellData,
      ['waterDepth', 'airGap', 'wellheadDepth'],
      'm',
      unitSettings.length,
      true,
    );
    reset(convertedWell);
    trigger();
  }, [well]);

  const debounceSaveWellDetails = useRef(
    debounce(saveWellDetails, autoSaveWait),
  );
  const onSubmit = handleSubmit((data) => {
    const convertedWell = convertInputUnits(
      data,
      ['waterDepth', 'airGap', 'wellheadDepth'],
      unitSettings.length,
      'm',
    );
    debounceSaveWellDetails.current(convertedWell);
  });
  useAutoSave(onSubmit, watch);

  const {
    wellConfiguration: wellConfigurationValue,
    drillingUnit: drillingUnitValue,
    location = DEFAULT_SITE_LOCATION,
    wellheadDepth,
    waterDepth,
    airGap,
  } = watch();

  const waterDepthLabel = t(
    wellConfigurationValue === wellConfiguration.ONSHORE &&
      drillingUnitValue === drillingUnit.LAND_RIG
      ? translations.settings_groundElevation
      : translations.settings_waterDepth,
  );

  const errorMessages = {
    wellDepthCannotBeDeeperThanSeaLevel: `${t(
      translations.settings_wellheadDepthCannotBeDeeperThanSeaLevel,
    )} ${t(translations.generalInputs_jackupSubsea)}`,
    wellDepthCannotBeDeeperThanSeabed: t(
      translations.settings_wellheadDepthCannotBeDeeperThanSeabed,
    ),
    wellDepthBetweenSeaLevelAndSeabed: t(
      translations.settings_generalInputs_wellheadDepthMustBeBetweenSeaLevelAndSeabed,
    ),
    wellDepthErrorOnshore: t(
      translations.settings_wellheadIsLocatedBelowTheSurface,
    ),
  };
  const ERROR_DELTA = 0.1;
  const getWellheadDepthError = () => {
    if (wellConfigurationValue === wellConfiguration.ONSHORE) {
      return null;
    }
    if (wellheadDepth - waterDepth - airGap > ERROR_DELTA) {
      return errorMessages.wellDepthCannotBeDeeperThanSeabed;
    }
    if (
      drillingUnitValue === drillingUnit.PLATFORM_JACKUP &&
      wellheadDepth - airGap > ERROR_DELTA
    ) {
      return errorMessages.wellDepthCannotBeDeeperThanSeaLevel;
    }
    if (
      drillingUnitValue !== drillingUnit.PLATFORM_JACKUP &&
      drillingUnitValue !== drillingUnit.TLP_HYBRID &&
      wellheadDepth <= airGap
    ) {
      return errorMessages.wellDepthBetweenSeaLevelAndSeabed;
    }
    return null;
  };

  const getWellheadDepthWarning = () => {
    switch (wellConfigurationValue) {
      case wellConfiguration.OFFSHORE: {
        return null;
      }
      case wellConfiguration.ONSHORE: {
        if (wellheadDepth > airGap) {
          return errorMessages.wellDepthErrorOnshore;
        }
        return null;
      }
      default: {
        return null;
      }
    }
  };

  const onWellConfigurationUpdated = (e) => {
    const updatedDrillingUnit =
      e.target.value === wellConfiguration.ONSHORE
        ? drillingUnit.LAND_RIG
        : drillingUnit.SEMI_SUBMERSIBLE;
    setValue('drillingUnit', updatedDrillingUnit);
    setValue('waterDepth', 0);
  };

  const drillingUnitMap = {
    [drillingUnit.SEMI_SUBMERSIBLE]: SEMI_SUBMERSIBLE,
    [drillingUnit.PLATFORM_JACKUP]: PLATFORM_JACKUP,
    [drillingUnit.TLP_HYBRID]: TLP_HYBRID,
  };

  const onDrillingUnitUpdated = (e) => {
    const updatedDrillingUnit = e.target.value;
    if (updatedDrillingUnit !== drillingUnit.LAND_RIG) {
      const updatedWellheadDepth =
        InitDrillingUnitType[drillingUnitMap[updatedDrillingUnit]];
      setValue('wellheadDepth', updatedWellheadDepth);
    }
  };

  const locationUiSettings = {
    locationSelectors: isPageDisabled
      ? ComponentRenderingType.Disabled
      : ComponentRenderingType.Enabled,
    locationCoordinate: isPageDisabled
      ? ComponentRenderingType.Disabled
      : ComponentRenderingType.Enabled,
    geographicCoordinate: ComponentRenderingType.Hidden,
  };

  return (
    <form>
      <Heading>{t(translations.settings_wellConfiguration)}</Heading>
      <Spacer height="var(--padding-xs)" />
      <FormRow>
        <Field label={t(translations.wellDetails_wellConfiguration)}>
          <Select
            name="wellConfiguration"
            control={control}
            errors={errors}
            options={wellConfigurationOptions}
            width="200px"
            onChange={(e) => onWellConfigurationUpdated(e)}
            disabled={isPageDisabled}
          />
        </Field>
        <Field label={t(translations.userSettings_drillingUnit)}>
          <Select
            name="drillingUnit"
            control={control}
            errors={errors}
            options={drillingUnitOptions.filter((item) =>
              wellConfigurationValue === wellConfiguration.ONSHORE
                ? item.value === drillingUnit.LAND_RIG
                : item.value !== drillingUnit.LAND_RIG,
            )}
            onChange={(e) => onDrillingUnitUpdated(e)}
            width="200px"
            disabled={isPageDisabled}
          />
        </Field>
        <Field label={waterDepthLabel} labelWidth={200}>
          <UnitInput
            name="waterDepth"
            control={control}
            errors={errors}
            disabled={isPageDisabled}
            unit={unitSettings?.length}
          />
        </Field>
        <Field label={t(translations.settings_airGap)} labelWidth={200}>
          <UnitInput
            name="airGap"
            control={control}
            errors={errors}
            disabled={isPageDisabled}
            unit={unitSettings?.length}
          />
        </Field>
        <Field label={t(translations.settings_wellheadDepth)} labelWidth={200}>
          <UnitInput
            name="wellheadDepth"
            control={control}
            errors={errors}
            error={getWellheadDepthError()}
            warning={getWellheadDepthWarning()}
            disabled={isPageDisabled}
            unit={unitSettings?.length}
          />
        </Field>
      </FormRow>
      <Divider margin={0} />
      <Spacer height="var(--padding-xs)" />
      <Heading>{t(translations.location)}</Heading>
      <Spacer height="var(--padding-xs)" />
      <LocationInput
        location={location}
        onChange={(newCoordinates) => {
          setValue('location', newCoordinates);
        }}
        locationUiSettings={locationUiSettings}
        showInRow
      />
      <Spacer height="var(--padding-xs)" />

      <Divider margin={0} />
      <Spacer />
      <WellSchematicTable
        wellDetailsId={well.wellDetailsId}
        onClickDelete={onClickDelete}
        isPageDisabled={isPageDisabled}
        sections={sections}
        wellEvents={wellEvents}
      />
      <Divider margin={0} />
      <Spacer />
      <Heading>{t(translations.settings_additionalSections)}</Heading>
      <Spacer height="var(--padding-xs)" />
      <AdditionalSectionsTable
        additionalSections={additionalSections}
        control={control}
        disabled={isPageDisabled}
        wellEvents={wellEvents}
      />
      <Spacer />
    </form>
  );
};

const mapStateToProps = ({ entities }) => {
  const {
    wellDetails: { sections, wellEvents, additionalSections },
    userSettings,
  } = entities;

  const wellConfigurationOptions = wellConfigurations.map((item) => ({
    ...item,
    label: i18n.t(item.label),
  }));
  const drillingUnitOptions = drillingUnits.map((item) => ({
    ...item,
    label: i18n.t(item.label),
  }));

  return {
    drillingUnitOptions,
    wellConfigurationOptions,
    unitSettings: userSettings.settings.units,
    sections: sections.filter((item) => !item.isAdditional),
    additionalSections,
    wellEvents,
  };
};

const mapDispatchToProps = { saveWellDetails };

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(DetailsForm),
);

export { Container as DetailsForm };
