import { createSelector } from '@reduxjs/toolkit';
import { sumBy } from 'lodash';
import { HOURS_PER_DAY } from '~src/enums/general';
import { round } from '@oliasoft-open-source/units';

const selectTimeTracker = (entities) => entities.timeTracker;

export const selectIsTimeTrackerLatest = createSelector(
  [selectTimeTracker],
  ({ isLatestVersion }) => isLatestVersion,
);

export const selectTimeTrackerFetching = createSelector(
  [selectTimeTracker],
  ({ isFetching }) => isFetching,
);

export const selectTimeTrackerAdding = createSelector(
  [selectTimeTracker],
  ({ isAdding }) => isAdding,
);

export const selectActualTime = createSelector(
  [selectTimeTracker],
  ({ list }) => round(sumBy(list, 'actual') / HOURS_PER_DAY, 2),
);
