import { createSelector } from '@reduxjs/toolkit';

const selectAuditLog = (entities) => entities.auditLog;

export const selectLogs = createSelector(
  [selectAuditLog],
  (auditLog) => auditLog.logs,
);

export const selectContributors = createSelector([selectLogs], (logs) => [
  ...new Set(logs?.map((log) => log.userName)),
]);
