import { useTranslation } from 'react-i18next';
import { ITableProps, Table } from '@oliasoft-open-source/react-ui-library';
import { ConnectedProps, connect } from 'react-redux';
import { selectPrimaryCurrency } from '~store/entities/company-settings/selectors';
import translations from '~src/internationalisation/translation-map.json';
import {
  simulationChartType,
  ProbabilityType,
  FIRST_INDEX,
} from '~src/enums/compare-estimates';
import { prettifyValue } from '~src/views/compare-estimates/utils/prettify-value';
import { highlightTheLowestInColumn } from '~src/views/compare-estimates/utils/highlight-the-lowest-in-column';
import { shownCumulativeProbabilities } from '~src/views/simulations/simulations';
import { timeCostView } from '~src/enums/simulations';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import { ICompareEstimates } from '~src/common/interfaces/compare-estimates.interfaces';
import type { TRootState } from '~src/store/store-types';

type TCombinedTableProps = PropsFromRedux & {
  estimates: ICompareEstimates[];
  estimateSelected: boolean;
  chartType: string;
  timeCostType: string;
};

const CombinedTable = ({
  primaryCurrency,
  estimates,
  estimateSelected,
  chartType,
  timeCostType,
}: TCombinedTableProps) => {
  const { t } = useTranslation();

  const tableSubHeaderName = (name: string) => {
    return <span style={{ fontWeight: 'normal', opacity: '0.5' }}>{name}</span>;
  };

  const P10Index = estimates[FIRST_INDEX]?.simulationResult.percentiles.indexOf(
    ProbabilityType.P10,
  );
  const P50Index = estimates[FIRST_INDEX]?.simulationResult.percentiles.indexOf(
    ProbabilityType.P50,
  );
  const P90Index = estimates[FIRST_INDEX]?.simulationResult.percentiles.indexOf(
    ProbabilityType.P90,
  );

  let tableData: ITableProps['table']['rows'];
  let headers: ITableProps['table']['headers'];
  let columnAlignment: ITableProps['table']['columnAlignment'];
  let columnHeaderAlignments: ITableProps['table']['columnHeaderAlignments'];

  const subHeaderCurrency = tableSubHeaderName(primaryCurrency);
  const subHeaderDays = tableSubHeaderName(t(translations.days));

  switch (chartType) {
    case simulationChartType.TOTAL_COST:
      tableData = estimates.map((estimate) => ({
        cells: [
          { value: estimate.name },
          {
            value: prettifyValue(estimate?.simulationResult?.average_cost),
          },
          {
            value: prettifyValue(
              estimate?.simulationResult?.cumulative_cost_percentiles?.[
                P10Index
              ],
            ),
          },
          {
            value: prettifyValue(
              estimate?.simulationResult?.cumulative_cost_percentiles?.[
                P50Index
              ],
            ),
          },
          {
            value: prettifyValue(
              estimate?.simulationResult?.cumulative_cost_percentiles?.[
                P90Index
              ],
            ),
          },
        ],
      }));
      headers = [
        {
          cells: [
            { value: t(translations.estimate) },
            { value: t(translations.simulations_mean) },
            { value: 'P10' },
            { value: 'P50' },
            { value: 'P90' },
          ],
        },
        {
          cells: [
            { value: '' },
            { value: subHeaderCurrency },
            { value: subHeaderCurrency },
            { value: subHeaderCurrency },
            { value: subHeaderCurrency },
          ],
        },
      ];
      columnAlignment = ['left', 'right', 'right', 'right', 'right'];
      columnHeaderAlignments = [
        'center',
        'center',
        'center',
        'center',
        'center',
      ];
      break;
    case simulationChartType.SIMULATION_TOTAL_TIME_AND_COST:
      tableData = estimates.map((estimate) => ({
        cells: [
          { value: estimate.name },
          {
            value: prettifyValue(estimate?.simulationResult?.average, true),
          },

          {
            value: prettifyValue(estimate?.simulationResult?.average_cost),
          },
          {
            value: prettifyValue(
              estimate?.simulationResult?.cumulative_percentiles?.[P10Index],
              true,
            ),
          },

          {
            value: prettifyValue(
              estimate?.simulationResult?.cumulative_cost_percentiles?.[
                P10Index
              ],
            ),
          },
          {
            value: prettifyValue(
              estimate?.simulationResult?.cumulative_percentiles?.[P50Index],
              true,
            ),
          },

          {
            value: prettifyValue(
              estimate?.simulationResult?.cumulative_cost_percentiles?.[
                P50Index
              ],
            ),
          },
          {
            value: prettifyValue(
              estimate?.simulationResult?.cumulative_percentiles?.[P90Index],
              true,
            ),
          },

          {
            value: prettifyValue(
              estimate?.simulationResult?.cumulative_cost_percentiles?.[
                P90Index
              ],
            ),
          },
        ],
      }));
      headers = [
        {
          cells: [
            {
              value: estimateSelected
                ? t(translations.estimate)
                : t(translations.design),
            },
            { value: 'Mean', colSpan: 2 },
            { value: 'P10', colSpan: 2 },
            { value: 'P50', colSpan: 2 },
            { value: 'P90', colSpan: 2 },
          ],
        },
        {
          cells: [],
        },
        {
          cells: [
            { value: '' },
            {
              value: subHeaderDays,
              width: '120px',
            },
            {
              value: subHeaderCurrency,
              width: '120px',
            },
            {
              value: subHeaderDays,
              width: '120px',
            },
            {
              value: subHeaderCurrency,
              width: '120px',
            },
            {
              value: subHeaderDays,
              width: '120px',
            },
            {
              value: subHeaderCurrency,
              width: '120px',
            },
            {
              value: subHeaderDays,
              width: '120px',
            },
            {
              value: subHeaderCurrency,
              width: '120px',
            },
          ],
        },
      ];
      columnAlignment = [
        'left',
        'right',
        'right',
        'right',
        'right',
        'right',
        'right',
        'right',
        'right',
      ];
      columnHeaderAlignments = [
        'center',
        'center',
        'center',
        'center',
        'center',
        'center',
        'center',
        'center',
        'center',
      ];
      break;
    case simulationChartType.CUMULATIVE_PROBABILITY:
      tableData = estimates.map((estimate) => ({
        cells: [
          { value: estimate.name },
          ...shownCumulativeProbabilities.map((index) => ({
            value: prettifyValue(
              timeCostType === timeCostView.TIME
                ? estimate?.simulationResult?.cumulative_percentiles?.[index]
                : estimate?.simulationResult?.cumulative_probability_cost
                    ?.costs?.[index] ??
                    (estimate?.simulationResult?.cumulative_probability_cost
                      ?.cost?.[index] as number),
              timeCostType === timeCostView.TIME,
            ),
          })),
        ],
      }));
      headers = [
        {
          cells: [
            { value: '' },
            ...shownCumulativeProbabilities?.map((item) => ({
              value: `P${item + 1}`,
            })),
          ],
        },
        {
          cells: [
            { value: '' },
            ...shownCumulativeProbabilities?.map(() => ({
              value:
                timeCostType === timeCostView.TIME
                  ? subHeaderDays
                  : subHeaderCurrency,
            })),
          ],
        },
      ];
      columnAlignment = [
        'left',
        ...Array(shownCumulativeProbabilities.length).fill('right'),
      ];
      columnHeaderAlignments = [
        ...Array(shownCumulativeProbabilities.length).fill('center'),
      ];
      break;
    default:
      tableData = [];
      break;
  }

  const table: ITableProps['table'] = {
    columnAlignment,
    columnHeaderAlignments,
    headers,
    rows: highlightTheLowestInColumn(tableData),
  };

  return <Table table={table} />;
};

const mapStateToProps = ({ entities }: TRootState) => {
  const primaryCurrency = selectPrimaryCurrency(entities);

  return {
    primaryCurrency,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Container = withErrorBoundary(connector(CombinedTable));

export { Container as CombinedTable };
