import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import {
  List,
  ListHeading,
  Spinner,
} from '@oliasoft-open-source/react-ui-library';
import { routes } from '~routes/routes';
import { generatePath } from '~store/navigation/navigation';
import { toggleVisible } from '~store/entities/compare-estimates/compare-estimates';
import { simulationStatuses } from '~src/enums/simulations';
import translations from '~src/internationalisation/translation-map.json';

const EstimatesList = ({ estimates, filteredEstimates, estimateSelected }) => {
  const { t } = useTranslation();
  const { company } = useParams();

  const items =
    estimates?.map((estimate, idx) => ({
      id: estimate.projectId,
      name: estimate.name,
      metadata: filteredEstimates[idx]?.wellName,
      onClick: () =>
        window.open(
          generatePath(routes.overview.fullPath, {
            company,
            project: estimate.projectId,
          }),
          '_blank',
        ),
      actions: [
        {
          icon:
            estimate?.isFetching ||
            (estimate?.simulation &&
              estimate.simulation.status !== simulationStatuses.FINISHED &&
              filteredEstimates.length > 0) ? (
              <Spinner colored tiny />
            ) : (
              false
            ),
        },
      ],
    })) || [];

  return (
    <>
      <ListHeading
        name={
          estimateSelected ? t(translations.estimates) : t(translations.design)
        }
      />
      <List
        list={{
          items,
        }}
        noHeader
      />
    </>
  );
};

const mapDispatchToProps = { toggleVisible };

const Container = connect(null, mapDispatchToProps)(EstimatesList);

export { Container as EstimatesList };
