import { costTrackerFields } from '~src/enums/cost-tracker';
import { getFields } from './get-ct-fields';

const columnWidthGroup = {
  minimum: [
    costTrackerFields.TIME_AFE,
    costTrackerFields.COST_AFE,
    costTrackerFields.TIME_ACTUAL,
    costTrackerFields.COST_ACTUAL,
    costTrackerFields.VARIANCE,
    costTrackerFields.CUMULATIVE_COST,
  ],
  maximum: [costTrackerFields.NAME, costTrackerFields.START],
};

const getFieldWidth = (field: costTrackerFields) => {
  if (columnWidthGroup.minimum.includes(field)) {
    return 1;
  }
  if (columnWidthGroup.maximum.includes(field)) {
    return 2;
  }
  return 1;
};

export const getColumnWidth = (disabledFields: string[] = []) => {
  const fields = getFields(disabledFields);
  const minFieldWidth = Math.floor(
    100 / fields.reduce((acc, field) => acc + getFieldWidth(field), 0),
  );
  return fields.map((field) =>
    field === costTrackerFields.NAME
      ? 'auto'
      : `${minFieldWidth * getFieldWidth(field)}%`,
  );
};
