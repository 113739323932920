import React, { useEffect, useRef, useState } from 'react';
import { debounce, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import {
  Heading,
  Field,
  FormRow,
  InputGroup,
  InputGroupAddon,
  Spacer,
  Button,
  HelpIcon,
  Flex,
  Menu,
} from '@oliasoft-open-source/react-ui-library';
import { getAllRigOperationTemplates } from '~src/store/entities/company-settings/company-settings';
import { getResolver } from '~src/validation/resolver';
import translations from '~src/internationalisation/translation-map.json';
import { tasksSchemaValidator } from '~schemas/ajv-validators';
import {
  Input,
  Select,
  UnitInput,
  TextArea,
  NumberInput,
  Toggle,
  UnitInputConvertable,
} from '~common/form-inputs';
import { estimateTypes, distributionTypes } from '~src/enums/tasks';
import {
  addTask,
  initialTask,
  getPreviewTimesFromOffsetWells,
} from '~store/entities/activity-model/activity-model';
import {
  convertInputUnits,
  convertToInputUnits,
  convertToStorageUnits,
} from '~common/units/units';
import { useAutoSave } from '~common/auto-save/use-auto-save';
import {
  isActivityWithSubs,
  selectExtendedLinkFields,
} from '~store/entities/activity-model/selectors';
import { BranchesTable } from '~views/activity-model/tasks/branches-table';
import { autoSaveWaitShort } from '~src/config/config';
import { selectSectionsDataStructure } from '~src/store/entities/sections-data-structure/selector';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import styles from './task.module.less';

const TaskForm = ({
  task,
  addTask,
  projectId,
  estimateTypesList,
  operation,
  unitSettings,
  linkFields,
  isPageDisabled,
  distributionTypesList,
  isRisk,
  showBranch,
  onClickDeleteTask,
  operationActivities,
  isFetching,
  getPreviewTimesFromOffsetWells,
  isFetchingPreviewTimes,
  rigOperationTemplates,
  getAllRigOperationTemplates,
  handleOnClickGetTimeFromOffsetWells,
}) => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'onBlur',
    defaultValues: task || initialTask,
    shouldFocusError: false,
    resolver: getResolver(tasksSchemaValidator),
  });
  const [isEditing, setIsEditing] = useState(true);

  const [
    estimateType,
    distributionType,
    withBranch,
    sectionsOperationActivityId,
    depthToLink,
    depthFromLink,
  ] = watch([
    'estimateType',
    'distribution',
    'withBranch',
    'sectionsOperationActivityId',
    'depthToLink',
    'depthFromLink',
  ]);
  const isOperationSpeed = estimateType === estimateTypes.OPERATION_SPEED;

  useEffect(() => {
    getAllRigOperationTemplates();
  }, []);

  const timeUnits = isOperationSpeed
    ? `${unitSettings.length}/${unitSettings.time}`
    : unitSettings.time;

  const templateClickHandler = (activity) => {
    const isOperationSpeed =
      activity.estimateType === estimateTypes.OPERATION_SPEED;

    const convertedTask = convertInputUnits(
      activity,
      ['min', 'most', 'max'],
      isOperationSpeed ? 'm/h' : 'h',
      isOperationSpeed
        ? `${unitSettings.length}/${unitSettings.time}`
        : unitSettings.time,
      true,
    );
    setValue('name', convertedTask.name);
    setValue('estimateType', convertedTask.estimateType);
    setValue('distribution', convertedTask.distribution);

    switch (convertedTask.distribution) {
      case distributionTypes.PERT:
        setValue('min', convertedTask.min);
        setValue('most', convertedTask.most);
        setValue('max', convertedTask.max);
        break;
      case distributionTypes.UNIFORM:
        setValue('min', convertedTask.min);
        setValue('max', convertedTask.max);
        break;
      default:
        setValue('most', convertedTask.most);
    }
  };

  useEffect(() => {
    const taskData = task || initialTask;
    const convertedLength = convertToInputUnits(
      taskData,
      ['from', 'to'],
      'm',
      unitSettings.length,
      true,
    );
    const convertedTask = convertInputUnits(
      convertedLength,
      ['min', 'most', 'max'],
      isOperationSpeed ? 'm/h' : 'h',
      isOperationSpeed
        ? `${unitSettings.length}/${unitSettings.time}`
        : unitSettings.time,
      true,
    );

    reset(convertedTask);
  }, [task?.taskId, reset]);

  const debounceAddTask = useRef(debounce(addTask, autoSaveWaitShort));

  const onSubmit = handleSubmit((data) => {
    const convertedLength = convertToStorageUnits(data, ['from', 'to'], {
      from: unitSettings.length,
      to: 'm',
    });
    const convertedTask = convertInputUnits(
      convertedLength,
      ['min', 'most', 'max'],
      isOperationSpeed
        ? `${unitSettings.length}/${unitSettings.time}`
        : unitSettings.time,
      isOperationSpeed ? 'm/h' : 'h',
    );
    convertedTask.operationId = operation.operationId;
    convertedTask.projectId = projectId;
    convertedTask.isApplyFromOffset = false;

    debounceAddTask.current(convertedTask);
  });

  useAutoSave(onSubmit, watch);

  const distributionFieldMap = {
    [distributionTypes.PERT]: [
      { label: t(translations.minimum), name: 'min' },
      { label: t(translations.mostLikely), name: 'most' },
      { label: t(translations.maximum), name: 'max' },
    ],
    [distributionTypes.UNIFORM]: [
      { label: t(translations.minimum), name: 'min' },
      { label: t(translations.maximum), name: 'max' },
    ],
    [distributionTypes.SPIKE]: [
      { label: t(translations.mostLikely), name: 'most' },
    ],
  };

  const distributionFieldOptions = {
    min: [
      {
        label: 'Offset Minimum',
        value: task?.offsetWellsTimeEstimate?.distribution?.A ?? 0,
      },
    ],
    most: [
      {
        label: 'Offset Average',
        value: task?.offsetWellsTimeEstimate?.distribution?.B ?? 0,
      },
    ],
    max: [
      {
        label: 'Offset Maximum',
        value: task?.offsetWellsTimeEstimate?.distribution?.C ?? 0,
      },
    ],
  };

  useEffect(() => {
    if (!isEmpty(task?.offsetWellsTimeEstimate) && task?.isApplyFromOffset) {
      const { min, most, max } = distributionFieldOptions;
      const taskWithTimeFromOffset = {
        ...task,
        min: min[0].value,
        most: most[0].value,
        max: max[0].value,
        distribution:
          task?.offsetWellsTimeEstimate?.distribution?.type ??
          distributionTypes.PERT,
        estimateType:
          task?.offsetWellsTimeEstimate?.estimateType ??
          estimateTypes.OPERATION_TIME,
      };

      const convertedTask = convertToInputUnits(
        taskWithTimeFromOffset,
        ['from', 'to'],
        'm',
        unitSettings.length,
        true,
      );

      reset(convertedTask);
    }
  }, [reset, task]);

  useEffect(() => {
    if (sectionsOperationActivityId && !isEditing) {
      const selectedOption = operationActivities.find(
        (option) => option.value === sectionsOperationActivityId,
      );
      setValue('name', selectedOption.label);
      setIsEditing(true);
    }
  }, [sectionsOperationActivityId]);

  const handleCategoryChange = (ev) => {
    setIsEditing(false);
    setValue('sectionsOperationActivityId', ev.target.value);
  };

  const operationActivity = operationActivities.find(
    (item) => item.value === sectionsOperationActivityId,
  );

  const isDrill = operationActivity?.label === 'Drill';

  const handleApplyClick = () => {
    const taskWithFlag = {
      ...task,
      isDrill,
    };
    handleOnClickGetTimeFromOffsetWells(projectId, taskWithFlag, operation);
  };

  return (
    <>
      <Flex justifyContent="space-between" alignItems="flex-start">
        <Heading top>{watch('name')}</Heading>
        <Field>
          <Button
            colored="danger"
            icon="delete"
            label={t(translations.activityModel_deleteActivity)}
            onClick={() => onClickDeleteTask(task)}
            small
          />
        </Field>
      </Flex>
      <form className={styles.taskForm}>
        <FormRow>
          {rigOperationTemplates && (
            <Field>
              <Menu
                menu={{
                  label: t(translations.activityModel_useTemplate),
                  trigger: 'DropDownButton',
                  sections: rigOperationTemplates.map((template) => ({
                    trigger: 'Text',
                    type: 'Menu',
                    menu: {
                      label: template.name,
                      placement: 'right-start',
                      trigger: 'Text',
                      sections: template?.activities?.map((activity) => ({
                        label: activity.name,
                        onClick: () => templateClickHandler(activity),
                        selected: false,
                        type: 'Option',
                      })),
                    },
                  })),
                }}
              />
            </Field>
          )}
          <Field>
            <Button
              loading={isFetching}
              disabled={!task?.sectionsOperationActivityId}
              label={t(translations.activityModel_applyAllTimesFromOffsetWells)}
              onClick={handleApplyClick}
            />
            <Spacer width="var(--padding-sm)" />
            <Button
              basic
              loading={isFetchingPreviewTimes}
              disabled={!task?.sectionsOperationActivityId}
              label={t(translations.preview)}
              onClick={() =>
                getPreviewTimesFromOffsetWells(
                  projectId,
                  sectionsOperationActivityId,
                  isDrill,
                )
              }
            />
          </Field>
        </FormRow>
        <FormRow>
          {!isRisk && (
            <Field label={t(translations.activity)}>
              <Select
                name="sectionsOperationActivityId"
                control={control}
                errors={errors}
                width={250}
                options={operationActivities}
                onChange={handleCategoryChange}
                placeholder={t(translations.activityModel_selectActivity)}
              />
            </Field>
          )}
          <Field label={isRisk ? 'Risk name' : t(translations.name)}>
            <Input
              name="name"
              control={control}
              errors={errors}
              disabled={isPageDisabled}
              width={250}
            />
          </Field>
        </FormRow>
        <FormRow>
          <Field label={t(translations.probability)}>
            <InputGroup width="120px">
              <NumberInput
                name="certainty"
                control={control}
                errors={errors}
                disabled={isPageDisabled}
              />
              <InputGroupAddon>%</InputGroupAddon>
            </InputGroup>
          </Field>
        </FormRow>
        <FormRow>
          <Field label={t(translations.estimateType)}>
            <Select
              name="estimateType"
              control={control}
              errors={errors}
              options={estimateTypesList}
              width="auto"
              disabled={isPageDisabled}
            />
          </Field>
          <Field label={t(translations.activityModel_distributionType)}>
            <Select
              name="distribution"
              control={control}
              errors={errors}
              options={distributionTypesList}
              width="auto"
              disabled={isPageDisabled}
            />
          </Field>
        </FormRow>
        {!!isRisk && showBranch && (
          <>
            <Toggle
              name="withBranch"
              label="Branch risk"
              control={control}
              disabled={isPageDisabled}
              noMargin
            />
            {withBranch && (
              <>
                <Spacer />
                <BranchesTable
                  operationId={operation.operationId}
                  projectId={projectId}
                  riskId={task.taskId}
                  estimateType={estimateType}
                  distributionType={distributionType}
                  onClickDeleteTask={onClickDeleteTask}
                  debounceAddTask={debounceAddTask}
                  isPageDisabled={isPageDisabled}
                />
              </>
            )}
            <Spacer />
          </>
        )}
        {!withBranch && (
          <>
            {estimateType === estimateTypes.OPERATION_SPEED && (
              <>
                <Heading>{t(translations.depth)}</Heading>
                <Spacer />
                <FormRow>
                  <Field label={t(translations.from)}>
                    <UnitInputConvertable
                      name="from"
                      unitkey="length"
                      control={control}
                      errors={errors}
                      predefinedOptions={linkFields}
                      selectedPredefinedOptionKey={depthFromLink}
                      onPredefinedChange={setValue}
                      disabled={isPageDisabled}
                      unitTemplate={unitSettings}
                    />
                  </Field>
                  <Field label={t(translations.to)}>
                    <UnitInputConvertable
                      name="to"
                      unitkey="length"
                      control={control}
                      errors={errors}
                      predefinedOptions={linkFields}
                      selectedPredefinedOptionKey={depthToLink}
                      onPredefinedChange={setValue}
                      disabled={isPageDisabled}
                      unitTemplate={unitSettings}
                    />
                  </Field>
                </FormRow>
              </>
            )}
            <Spacer />
            <Heading>
              {estimateType === estimateTypes.OPERATION_SPEED
                ? t(translations.activityModel_operationSpeed)
                : t(translations.time)}
            </Heading>
            <FormRow>
              {distributionFieldMap[distributionType]?.map((field) => (
                <Field label={field.label} key={field.name}>
                  <UnitInput
                    name={field.name}
                    unit={timeUnits}
                    control={control}
                    errors={errors}
                    disabled={isPageDisabled}
                    predefinedOptions={distributionFieldOptions[field.name]}
                    width="142px"
                  />
                </Field>
              ))}
            </FormRow>
          </>
        )}
        <Field label={t(translations.comments)}>
          <TextArea
            name="comments"
            control={control}
            errors={errors}
            rows={5}
            disabled={isPageDisabled}
          />
        </Field>
      </form>
    </>
  );
};

const mapStateToProps = ({ entities }, ownProps) => {
  const {
    activityModel: { operations, isFethcing, isFetchingPreviewTimes },
    companySettings: { rigOperationTemplates },
  } = entities;

  const { activities } = selectSectionsDataStructure(entities);

  const activeOperation = operations.filter((operation) => operation.active)[0];

  const operationActivities = activities
    .filter(
      (activity) =>
        activeOperation?.sectionsOperationId === activity?.sectionsOperationId,
    )
    .map((activity) => ({
      label: activity.name,
      value: activity.sectionsOperationActivityId,
    }));

  return {
    estimateTypesList: [
      { label: 'Operation time', value: estimateTypes.OPERATION_TIME },
      { label: 'Operation speed', value: estimateTypes.OPERATION_SPEED },
    ],
    distributionTypesList: [
      {
        label: 'Spike',
        value: distributionTypes.SPIKE,
        icon: (
          <HelpIcon
            text={i18n.t(
              translations.activityModel_spikeDistributionExplanation,
            )}
          />
        ),
      },
      {
        label: 'Uniform',
        value: distributionTypes.UNIFORM,
        icon: (
          <HelpIcon
            text={i18n.t(
              translations.activityModel_uniformDistributionExplanation,
            )}
          />
        ),
      },
      {
        label: 'Pert',
        value: distributionTypes.PERT,
        icon: (
          <HelpIcon
            text={i18n.t(
              translations.activityModel_pertDistributionExplanation,
            )}
          />
        ),
      },
    ],
    unitSettings: entities.userSettings.settings.units,
    linkFields: selectExtendedLinkFields({ entities }),
    isRisk: ownProps.task?.parentId,
    showBranch: !isActivityWithSubs(
      { entities },
      ownProps.operation?.operationId,
      ownProps.task?.taskId,
    ),
    operationActivities,
    isFethcing,
    isFetchingPreviewTimes,
    rigOperationTemplates,
  };
};

const mapDispatchToProps = {
  addTask,
  getPreviewTimesFromOffsetWells,
  getAllRigOperationTemplates,
};

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(TaskForm),
);

export { Container as TaskForm };
