import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import {
  Flex,
  Grid,
  Page,
  Heading,
  Row,
  Column,
  Spacer,
  Button,
  Divider,
} from '@oliasoft-open-source/react-ui-library';
import { round } from '@oliasoft-open-source/units';
import { navigateToPath } from '~store/navigation/navigation';
import { formatToMillions } from '~common/format-data/format-to-millions';
import { ProjectSettingsModal } from '~src/views/settings/project-settings/project-settings.modal';
import {
  getSimulations,
  simulationCleanup,
} from '~store/entities/simulations/simulations';
import {
  getTimeTrackerItems,
  listCleared as timeTrackerCleanUp,
} from '~store/entities/time-tracker/time-tracker';
import {
  getCostTrackerItems,
  listCleared as costTrackerCleanUp,
} from '~store/entities/cost-tracker/cost-tracker';
import {
  getOperations,
  operationsCleared,
} from '~store/entities/activity-model/activity-model';
import { selectSimulationsResult } from '~store/entities/simulations/selectors';
import {
  selectTimeTrackerFetching,
  selectActualTime,
} from '~store/entities/time-tracker/selectors';
import { selectActualCost } from '~store/entities/cost-tracker/selectors';
import {
  selectOperationList,
  selectActivityModelLoaders,
} from '~store/entities/activity-model/selectors';
import {
  getProjectSettings,
  showProjectSettingsModalUpdated,
} from '~store/entities/project-settings/project-settings';
import { selectProjectOverview } from '~store/entities/projects/selectors';
import { selectContributors } from '~store/entities/audit-log/selectors';
import translations from '~src/internationalisation/translation-map.json';
import { ResultChartGrid } from '~src/views/simulations/result-overview/result-chart-grid';
import { Report } from '~src/views/report/report';
import {
  getMean,
  getPercentiles,
} from '~src/views/simulations/result-overview/result-utils';
import { formatFromApiDate } from '~src/views/time-tracker/utils/date/dateUtils';
import { AuditLog } from '~views/audit-log/audit-log';
import { updateNumberOfCharts } from '~src/store/entities/ui/simulations/chart-controls';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import { isEmpty } from 'lodash';
import { PageCard } from './page-card';
import { OverviewDrawer } from './drawer/overview.drawer';
import './style.css';

const Overview = ({
  projectOverview,
  showProjectSettingsModal,
  showProjectSettingsModalUpdated,
  getProjectSettings,
  updateNumberOfCharts,
  simulationsResult,
  lastTimeTrackerOperation,
  startDate,
  operations,
  isTimeTrackerFetching,
  isOperationsFetching,
  actualTime,
  actualCost,
  contributors,
  getSimulations,
  simulationCleanup,
  getTimeTrackerItems,
  timeTrackerCleanUp,
  getCostTrackerItems,
  costTrackerCleanUp,
  getOperations,
  operationsCleared,
}) => {
  const { t } = useTranslation();
  const { project } = useParams();

  useEffect(() => {
    getProjectSettings(project);
    updateNumberOfCharts(2);
  }, []);

  useEffect(() => {
    if (project) {
      getOperations(project);
      getSimulations(project);
      getTimeTrackerItems(project);
      getCostTrackerItems(project);
    }
    return () => {
      simulationCleanup();
      timeTrackerCleanUp();
      costTrackerCleanUp();
      operationsCleared();
    };
  }, [project]);

  const totalTimeMain = round(simulationsResult?.average / 24, 1);
  const totalTimeSeconary = () => {
    const time10 = round(getPercentiles(simulationsResult, 10, 'time') / 24, 1);
    const time90 = round(getPercentiles(simulationsResult, 90, 'time') / 24, 1);
    if ((time10 && time90) || time10 === 0 || time90 === 0) {
      return `${time10} - ${time90} ${t(
        translations.days,
      ).toLocaleLowerCase()}`;
    } else {
      return '';
    }
  };

  const totalCostMain = formatToMillions(simulationsResult?.average_cost);
  const totalCostSeconary = () => {
    const cost10 = formatToMillions(
      getPercentiles(simulationsResult, 10, 'cost'),
    );
    const cost90 = formatToMillions(
      getPercentiles(simulationsResult, 90, 'cost'),
    );
    if ((cost10 && cost90) || cost10 === 0 || cost90 === 0) {
      return `$${cost10} m - $${cost90} m`;
    } else {
      return '';
    }
  };

  const riskContribution =
    operations?.length && !isEmpty(simulationsResult)
      ? round(
          operations
            .flatMap((operation) =>
              operation.tasks
                .filter((task) => !task.isBranch && task.parentId)
                .reduce((sum, risk) => sum + getMean(risk, operation.tasks), 0),
            )
            .reduce(
              (totalMean, operationMean) => totalMean + operationMean,
              0,
            ) / 24,
          1,
        )
      : '';

  const reportRef = useRef(null);

  // Call the child's exposed function
  const callGenerateReport = () => {
    if (reportRef.current) {
      reportRef.current.generateReport();
    }
  };
  const summaryData = {
    estimatedTime: totalTimeMain,
    actualTime,
    estimatedCost: totalCostMain,
    actualCost: formatToMillions(actualCost),
    risk: riskContribution,
    contributors,
  };
  return (
    <>
      <Page padding={0} scroll={false}>
        <Row spacing={0} height="100%">
          <OverviewDrawer />
          <Column scroll>
            <div className="overview-wrapper">
              <div className="overview-header">
                <Flex justifyContent="space-between" gap>
                  <Heading top marginBottom={0} testId="overview-heading">
                    {projectOverview.name} ({t(translations.wellbore)})
                  </Heading>
                  <Flex gap>
                    <Button
                      name="project-settings"
                      label={t(translations.projects_projectSettings)}
                      onClick={() => showProjectSettingsModalUpdated(true)}
                    />
                    <Button
                      name="download-report"
                      label={t(translations.projects_downloadReport)}
                      onClick={() => callGenerateReport()}
                    />
                  </Flex>
                </Flex>
                <Spacer />
                <Divider margin={0} />
                <Grid
                  columns="repeat(4, 1fr)"
                  columnsTablet="repeat(3, 1fr)"
                  gap
                  justifyContent="space-between"
                >
                  <PageCard
                    title={t(translations.simulations_totalTime)}
                    mainValue={
                      totalTimeMain || totalTimeMain === 0
                        ? `${totalTimeMain} ${t(
                            translations.days,
                          ).toLocaleLowerCase()}`
                        : ''
                    }
                    secondaryValue={totalTimeSeconary()}
                  />
                  <PageCard
                    title={t(translations.costSetup_totalCost)}
                    mainValue={
                      totalCostMain || totalCostMain === 0
                        ? `$${totalCostMain} m`
                        : ''
                    }
                    secondaryValue={totalCostSeconary()}
                  />
                  <PageCard
                    title={t(translations.estimatedFinish)}
                    mainValue={
                      simulationsResult?.operations &&
                      !lastTimeTrackerOperation?.finish
                        ? t(translations.notFound)
                        : formatFromApiDate(lastTimeTrackerOperation?.finish)
                    }
                    secondaryValue={''}
                    isLoading={isTimeTrackerFetching}
                  />
                  <PageCard
                    title={t(translations.projects_riskContribution)}
                    mainValue={
                      riskContribution || riskContribution === 0
                        ? `${riskContribution} ${t(
                            translations.days,
                          ).toLocaleLowerCase()}`
                        : ''
                    }
                    secondaryValue={''}
                    isLoading={isOperationsFetching}
                  />
                </Grid>
              </div>
              <Report
                reportRef={reportRef}
                simulationsResult={simulationsResult}
                projectOverview={{ ...projectOverview, startDate }}
                summaryData={summaryData}
              />
              <ResultChartGrid />
              <Divider margin={0} />
              <div className="overview-audit-log">
                <AuditLog />
              </div>
            </div>
          </Column>
        </Row>
      </Page>
      {showProjectSettingsModal && (
        <ProjectSettingsModal
          setModalVisible={showProjectSettingsModalUpdated}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ entities }) => {
  const { isFetching: isOperationsFetching } = selectActivityModelLoaders({
    entities,
  });
  return {
    projectOverview: selectProjectOverview(entities),
    showProjectSettingsModal: entities.projectSettings.showProjectSettingsModal,
    simulationsResult: selectSimulationsResult(entities),
    isTimeTrackerFetching: selectTimeTrackerFetching(entities),
    lastTimeTrackerOperation: entities?.timeTracker?.list?.slice(-1)[0],
    startDate: entities?.timeTracker?.list?.[0]?.start,
    operations: selectOperationList({ entities }),
    isOperationsFetching,
    actualTime: selectActualTime(entities),
    actualCost: selectActualCost(entities),
    contributors: selectContributors(entities),
  };
};

const mapDispatchToProps = {
  navigateToPath,
  showProjectSettingsModalUpdated,
  getProjectSettings,
  updateNumberOfCharts,
  getSimulations,
  simulationCleanup,
  getTimeTrackerItems,
  timeTrackerCleanUp,
  getCostTrackerItems,
  costTrackerCleanUp,
  getOperations,
  operationsCleared,
};

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(Overview),
  { isPage: true },
);

export { Container as Overview };
