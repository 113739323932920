import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';

import {
  runSimulations,
  getSimulations,
  updateSimulationStatus,
  calcReceived,
  simulationDone,
  simulationCleanup,
} from '~store/entities/simulations/simulations';

import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import ResultChart from '../simulations/result-overview/result-chart';
import '../simulations/result-overview/style.less';

const ResultChartGrid = ({
  selectedItem,
  selectedDataEntity,
  getSimulations,
  simulations,
  simulationCleanup,
}) => {
  const { project: projectId } = useParams();
  useEffect(() => {
    if (projectId) {
      getSimulations(projectId);
    }

    return () => simulationCleanup();
  }, [projectId]);

  useEffect(() => {
    if (simulations.simulationStatus.status === 'Finished') {
      simulationDone();
      getSimulations(projectId);
    }
  }, [simulations.simulationStatus]);

  return (
    <ResultChart
      selectedItem={selectedItem}
      selectedDataEntity={selectedDataEntity}
      simulationsResult={simulations?.simulationsResult}
      controlsPortalId={'controls'}
    />
  );
};

const mapStateToProps = ({ entities }) => {
  const { simulations } = entities;

  return {
    simulations,
  };
};

const mapDispatchToProps = {
  runSimulations,
  getSimulations,
  updateSimulationStatus,
  calcReceived,
  simulationCleanup,
};

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(ResultChartGrid),
);

export { Container as ResultChartGrid };
