import jsPDF from "jspdf";

export const addPageTitle = (
    doc: jsPDF,
    title: string = '',
    positionX: number,
    positionY: number,
  ) => {
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(24);
    doc.text(title, positionX, positionY);
  };