import jsPDF from "jspdf";
import { format } from "date-fns";
import { IProjectsOverview } from "~src/common/interfaces/project";
import oliasoftLogo from '~src/assets/logo.png';
import forecastLogo from '~src/assets/logo-forecast.png';
import { fontMainSize, fontSecondarySize, fontSmallSize } from "./common";
import { addHierarchyData } from "./add-hierarchy-data";

export const addTitlePage = (doc: jsPDF, projectOverview: IProjectsOverview) => {
  const pdfWidth = doc.internal.pageSize.getWidth();
  const pdfHeight = doc.internal.pageSize.getHeight();
  const oliasofLogoWidth = 58; // Width of the image
  const oliasofLogoHeight = oliasofLogoWidth;
  const centerX = (pdfWidth - oliasofLogoWidth) / 2;
  const initialY = 60;

  doc.addImage(
    oliasoftLogo,
    'PNG',
    centerX,
    initialY,
    oliasofLogoWidth,
    oliasofLogoHeight,
  );
  doc.setFont('helvetica', 'bold');
  doc.setFontSize(fontMainSize);
  doc.text(
    projectOverview.name,
    pdfWidth / 2,
    initialY + oliasofLogoHeight + fontMainSize / 2,
    {
      align: 'center',
    },
  );
  doc.setFont('helvetica', 'normal');
  doc.text(
    projectOverview.design.name,
    pdfWidth / 2,
    initialY + oliasofLogoHeight + fontMainSize,
    {
      align: 'center',
    },
  );
  doc.setFontSize(fontSecondarySize);
  doc.text(
    `${projectOverview.companyName} \u00B7 ${format(
      new Date(),
      'd.LLL-yyyy',
    )}`,
    pdfWidth / 2,
    initialY + oliasofLogoHeight + fontMainSize * 2,
    { align: 'center' },
  );
  addHierarchyData(doc, projectOverview);

  const forecastLogoProps = doc.getImageProperties(forecastLogo);
  const forecastLogoWidth = 35;
  const forecastLogoHeight =
    (forecastLogoProps.height * forecastLogoWidth) / forecastLogoProps.width;
  const fontSize = doc.getFontSize();
  const lineHeigth = doc.getLineHeight();
  doc.addImage(
    forecastLogo,
    'PNG',
    pdfWidth / 2 + 5,
    pdfHeight - forecastLogoHeight - 10,
    forecastLogoWidth,
    forecastLogoHeight,
  );
  doc.setTextColor('#90908d');
  doc.setFontSize(fontSmallSize);

  doc.text(
    'Soft catalyst for hard change',
    pdfWidth / 2 - 5,
    pdfHeight - forecastLogoHeight / 2 + (lineHeigth - fontSize) - 10,
    { align: 'right' },
  );
};