import { useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import jsPDF from 'jspdf';
import translations from '~src/internationalisation/translation-map.json';
import { simulationChartType } from '~src/enums/simulations';
import { IProjectsOverview } from '~src/common/interfaces/project';
  import { IReportRef, ISummaryData } from '~src/common/interfaces/report';
import forecastLogo from '~src/assets/logo-forecast.png';
import { ResultChartGrid } from './report-chart';
import { forecastLogoWidth } from './utils/common';
import { addTitlePage } from './utils/add-title-page';
import { addSummaryPage } from './utils/add-summary-page';
import { addSectionWaterfallTimePage } from './utils/add-section-waterfall-time';
import { addSectionWaterfallCostPage } from './utils/add-section-waterfall-cost';

const Report = ({
  reportRef,
  simulationsResult,
  projectOverview,
  summaryData
}: {
  reportRef: {current: IReportRef};
  simulationsResult: any;
  projectOverview: IProjectsOverview;
  summaryData: ISummaryData
}) => {
  const { t } = useTranslation();


  const hiddenContainerRef = useRef(null);
  const waterfallTimeChartRef = useRef(null);
  const waterfallCostChartRef = useRef(null);

  const generateReport = async () => {
    // eslint-disable-next-line new-cap
    const pdf = new jsPDF('p', 'mm', 'a4');
    const forecastLogoProps = pdf.getImageProperties(forecastLogo);
    const forecastLogoHeight =
      (forecastLogoProps.height * forecastLogoWidth) / forecastLogoProps.width;
    const padding = 10;

    addTitlePage(pdf, projectOverview);
    addSummaryPage(pdf, padding, forecastLogoHeight, projectOverview, summaryData);
    await addSectionWaterfallTimePage(pdf, padding, forecastLogoHeight, waterfallTimeChartRef, simulationsResult, projectOverview);
    await addSectionWaterfallCostPage(pdf, padding, forecastLogoHeight, waterfallCostChartRef, simulationsResult, projectOverview);

    pdf.save(`${projectOverview?.name?.replace(/\s/g, "_") || 'report'}.pdf`);
  };

  useImperativeHandle(reportRef, () => ({
    generateReport, // Expose the function
  }));

  return (
    <div>
      {/* Hidden container to render the component */}
      <div
        ref={hiddenContainerRef}
        style={{
          position: 'absolute',
          top: '-9999px',
          left: '-9999px',
          opacity: 0,
        }}
      >
        <div ref={waterfallTimeChartRef} style={{ width: '800px' }}>
          <ResultChartGrid
            selectedItem={{
              label: t(translations.simulations_sectionWaterfall),
              value: simulationChartType.SECTION_WATERFALL,
            }}
            selectedDataEntity={0}
          />
        </div>
        <div ref={waterfallCostChartRef} style={{ width: '800px' }}>
          <ResultChartGrid
            selectedItem={{
              label: t(translations.simulations_sectionWaterfall),
              value: simulationChartType.SECTION_WATERFALL,
            }}
            selectedDataEntity={1}
          />
        </div>
      </div>
    </div>
  );
};

export { Report };
