import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TbFolderOpen, TbDownload } from 'react-icons/tb';
import { sum } from 'lodash';
import {
  round,
  displayNumber,
  roundToFixed,
} from '@oliasoft-open-source/units';
import {
  Table,
  Flex,
  Heading,
  Menu,
  Button,
  toast,
  Portal,
  Spacer,
  Icon,
  Text,
} from '@oliasoft-open-source/react-ui-library';
import { simulationChartType } from '~src/enums/simulations';
import translations from '~src/internationalisation/translation-map.json';
import { selectPrimaryCurrency } from '~store/entities/company-settings/selectors';
import { decimalSeparator, HOURS_PER_DAY } from '~src/enums/general';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import {
  convertOperationDurations,
  convertOperationVsDepthDurations,
  getRowsData,
  unParse,
  shownCumulativeProbabilities,
} from '../simulations';
import './style.less';
import {
  getMean,
  getSimulationDataForWaterfall,
  sectionObjectKeys,
  normalizeValue,
} from './result-utils';

const ResultTable = ({
  simulationsResult,
  selectedItem,
  selectedDataEntity,
  primaryCurrency,
  controlsPortalId,
  operations,
  chartKey,
  riskFilter,
}) => {
  const { t } = useTranslation();
  const [closedIds, setClosedIds] = useState([]);

  let tableData = null;
  const subactivity = simulationsResult?.cumulative_subactivity_percentiles
    ?.filter((_, index) => shownCumulativeProbabilities.includes(index))
    ?.map((el) => el / 24);
  const toggleExpandedRow = (id) =>
    closedIds.includes(id)
      ? setClosedIds(closedIds.filter((existingId) => existingId !== id))
      : setClosedIds([...closedIds, id]);

  switch (selectedItem?.value) {
    case simulationChartType.CUMULATIVE_PROBABILITY:
      {
        const values =
          selectedDataEntity === 0
            ? simulationsResult?.cumulative_probability?.time?.map(
                (t) => t / HOURS_PER_DAY,
              )
            : simulationsResult?.cumulative_probability_cost?.costs ??
              simulationsResult?.cumulative_probability_cost?.cost;
        tableData = values?.filter((_, index) =>
          shownCumulativeProbabilities.includes(index),
        );
      }
      break;

    case simulationChartType.OPERATION_DURATIONS:
      {
        const labels =
          selectedDataEntity === 0
            ? simulationsResult?.operation_durations?.labels
            : simulationsResult?.operation_costs?.labels;
        const values =
          selectedDataEntity === 0
            ? convertOperationDurations(
                simulationsResult?.operation_durations?.durations,
              )
            : simulationsResult?.operation_costs?.costs;
        tableData = labels?.map((item, index) => ({
          label: item,
          P10: values['10'][index],
          P50: values['50'][index],
          P90: values['90'][index],
          mean: values.mean[index],
        }));
      }
      break;

    case simulationChartType.SECTION_DURATIONS:
      {
        const labels = simulationsResult?.section_durations?.labels;
        const values = convertOperationDurations(
          simulationsResult?.section_durations?.durations,
        );
        tableData = labels?.map((item, index) => ({
          label: item,
          P10: values['10'][index],
          P50: values['50'][index],
          P90: values['90'][index],
          mean: values.mean[index],
        }));
      }
      break;

    case simulationChartType.TIME_DEPTH:
      {
        const operationVsDepth =
          selectedDataEntity === 0
            ? convertOperationVsDepthDurations(
                simulationsResult?.operation_vs_depth,
              )
            : simulationsResult?.operation_cost_vs_depth;
        tableData = operationVsDepth;
      }
      break;
    case simulationChartType.ACTIVITY_DURATIONS:
      {
        const operations = simulationsResult?.operations;
        tableData = operations?.map((item) => ({
          label: item.name,
          activity: item.actions,
        }));
      }
      break;
    case simulationChartType.COST_ITEM_OVERVIEW:
      tableData = simulationsResult?.cost_results;
      break;
    case simulationChartType.SECTION_WATERFALL:
      tableData =
        selectedDataEntity === 0
          ? sum(simulationsResult?.section_durations?.durations?.mean)
          : sum(simulationsResult?.section_costs?.costs?.mean);
      break;
    case simulationChartType.RISKS: {
      const filterMap = riskFilter.reduce(
        (acc, curr) => ({ ...acc, [curr.value]: curr.selected }),
        {},
      );

      const datasets = operations
        .filter(
          (operation) =>
            filterMap[operation.operationId] &&
            filterMap[operation.projectSectionId],
        )
        .reduce((riskData, operation) => {
          const operationTaskData = operation.tasks
            .filter((task) => !task.isBranch && task.parentId !== null)
            .map((task) => ({
              name: task.name,
              certainty: task.certainty,
              mean: getMean(task, operation.tasks),
            }));
          return [...riskData, ...operationTaskData];
        }, []);
      tableData = datasets;

      break;
    }
    default:
      tableData = [];
      break;
  }

  const generateTableHeaders = (selectedType) => {
    const tableSubHeader = tableSubHeaderName(
      selectedDataEntity === 0 ? t(translations.days) : primaryCurrency,
    );
    switch (selectedType) {
      case simulationChartType.OPERATION_DURATIONS:
      case simulationChartType.SECTION_DURATIONS:
      case simulationChartType.TIME_DEPTH:
        return [
          {
            cells: [
              {
                value:
                  selectedType === simulationChartType.TIME_DEPTH
                    ? t(translations.depth)
                    : t(translations.operation),
              },
              { value: 'Mean' },
              { value: 'P10' },
              { value: 'P50' },
              { value: 'P90' },
            ],
          },
          {
            cells: [
              { value: '' },
              { value: tableSubHeader },
              { value: tableSubHeader },
              { value: tableSubHeader },
              { value: tableSubHeader },
            ],
          },
        ];
      case simulationChartType.CUMULATIVE_PROBABILITY:
        return [
          {
            cells: [
              { value: '' },
              ...shownCumulativeProbabilities?.map((item) => ({
                value: `P${item + 1}`,
              })),
            ],
          },
          {
            cells: [
              { value: '' },
              ...shownCumulativeProbabilities?.map(() => ({
                value: tableSubHeader,
              })),
            ],
          },
        ];
      case simulationChartType.ACTIVITY_DURATIONS:
        return [
          {
            cells: [
              { value: t(translations.simulations_activity) },
              { value: t(translations.simulations_mean) },

              { value: 'P10' },
              { value: 'P50' },
              { value: 'P90' },
            ],
          },
          {
            cells: [
              { value: '' },
              { value: tableSubHeaderName(t(translations.hours)) },
              { value: tableSubHeaderName(t(translations.hours)) },
              { value: tableSubHeaderName(t(translations.hours)) },
              { value: tableSubHeaderName(t(translations.hours)) },
            ],
          },
        ];

      case simulationChartType.COST_ITEM_OVERVIEW:
        return [
          {
            cells: [
              {
                value: t(translations.projects_costItems),
              },
              { value: 'Mean' },
              { value: 'P10' },
              { value: 'P50' },
              { value: 'P90' },
            ],
          },
          {
            cells: [
              { value: '' },
              { value: tableSubHeader },
              { value: tableSubHeader },
              { value: tableSubHeader },
              { value: tableSubHeader },
            ],
          },
        ];
      case simulationChartType.RISKS:
        return [
          {
            cells: [
              {
                value: t(translations.risks_risks),
              },
              { value: t(translations.simulations_probabilityOfOccurring) },
              { value: `${t(translations.simulations_meanTime)}` },
            ],
          },
          {
            cells: [
              { value: '' },
              { value: tableSubHeaderName(t(translations.percent)) },
              { value: tableSubHeaderName(t(translations.hours)) },
            ],
          },
        ];
      case simulationChartType.SECTION_WATERFALL: {
        const { dataType, threshold } = getSimulationDataForWaterfall(
          simulationsResult,
          selectedDataEntity,
          HOURS_PER_DAY,
        );
        return [
          {
            cells: [
              { value: `${t(translations.section)}` },
              {
                value:
                  dataType === sectionObjectKeys.DURATIONS
                    ? t(translations.days)
                    : `${t(translations.cost)} ${
                        threshold
                          ? `[M${primaryCurrency}]`
                          : `[${primaryCurrency}]`
                      }`,
              },
            ],
          },
        ];
      }
      default:
        return [];
    }
  };

  const tableRowName = (name) => {
    return <span className="table-row-name">{name}</span>;
  };

  const tableSubHeaderName = (name) => {
    return <span className="table-subheader-name">{name}</span>;
  };

  const generateTableAlignment = (selectedType) => {
    let columnAlignment = [];

    if (selectedType) {
      switch (selectedType) {
        case simulationChartType.ACTIVITY_DURATIONS:
          columnAlignment = ['left', 'right', 'right', 'right', 'right'];
          break;
        case simulationChartType.RISKS:
          columnAlignment = ['left', 'right', 'right'];
          break;
        case simulationChartType.COST_ITEM_OVERVIEW:
        case simulationChartType.OPERATION_DURATIONS:
        case simulationChartType.SECTION_DURATIONS:
        case simulationChartType.TIME_DEPTH:
          columnAlignment = ['left', 'right', 'right', 'right', 'right'];
          break;
        case simulationChartType.CUMULATIVE_PROBABILITY:
          columnAlignment = [
            'left',
            'right',
            'right',
            'right',
            'right',
            'right',
            'right',
            'right',
            'right',
            'right',
            'right',
            'right',
          ];
          break;

        default:
      }
    }

    return columnAlignment;
  };

  const generateRows = (tableData, selectedType) => {
    let result = [];
    const getNumberValue = (value) =>
      displayNumber(round(value, 2), {
        scientific: false,
      });
    const createCostRow = (item, depth) => {
      const rowStyle = {
        style: {
          backgroundColor: item?.children ? 'var(--color-background)' : '',
        },
      };

      result.push({
        onRowClick: () => toggleExpandedRow(item.id),
        cells: [
          {
            ...rowStyle,
            value: (
              <>
                <Spacer width={21 * depth} />
                {item?.children && (
                  <>
                    <Icon
                      icon={
                        closedIds.includes(item.id) ? (
                          'folder'
                        ) : (
                          <TbFolderOpen />
                        )
                      }
                    />
                    <Spacer width="8px" />
                  </>
                )}
                <Text>{item.name}</Text>
              </>
            ),
          },
          {
            ...rowStyle,
            value: getNumberValue(item.results[0]),
          },
          {
            ...rowStyle,
            value: getNumberValue(item.results[1]),
          },
          {
            ...rowStyle,
            value: getNumberValue(item.results[2]),
          },
          {
            ...rowStyle,
            value: getNumberValue(item.results[3]),
          },
        ],
      });

      if (item.children && item.children.length > 0) {
        if (!closedIds.includes(item.id)) {
          item.children.forEach((item) => {
            createCostRow(item, depth + 1);
          });
        }
      }
    };

    if (tableData) {
      switch (selectedType) {
        case simulationChartType.OPERATION_DURATIONS:
        case simulationChartType.SECTION_DURATIONS:
          result = tableData?.map((el) => {
            if (selectedDataEntity == 0) {
              return {
                cells: [
                  { value: tableRowName(el.label) },
                  { value: roundToFixed(el.mean, 2) },
                  { value: roundToFixed(el.P10, 2) },
                  { value: roundToFixed(el.P50, 2) },
                  { value: roundToFixed(el.P90, 2) },
                ],
              };
            } else {
              return {
                cells: [
                  { value: tableRowName(el.label) },
                  {
                    value: displayNumber(round(el.mean, 0), {
                      scientific: false,
                    }),
                  },
                  {
                    value: displayNumber(round(el.P10, 0), {
                      scientific: false,
                    }),
                  },
                  {
                    value: displayNumber(round(el.P50, 0), {
                      scientific: false,
                    }),
                  },
                  {
                    value: displayNumber(round(el.P90, 0), {
                      scientific: false,
                    }),
                  },
                ],
              };
            }
          });
          break;

        case simulationChartType.CUMULATIVE_PROBABILITY:
          result = [
            {
              cells: [
                {
                  value: t(
                    selectedDataEntity == 0
                      ? translations.simulations_estimatedTime
                      : translations.simulations_estimatedCost,
                  ),
                },
                ...tableData.map((el) => {
                  if (selectedDataEntity == 0) {
                    return { value: round(el, 2) };
                  } else {
                    return {
                      value: displayNumber(round(el, 0), {
                        scientific: false,
                      }),
                    };
                  }
                }),
              ],
            },
            selectedDataEntity == 0 &&
              subactivity && {
                cells: [
                  { value: t(translations.simulations_riskOnly) },
                  ...subactivity?.map((el) => ({ value: round(el, 2) })),
                ],
              },
          ].filter(Boolean);
          break;
        case simulationChartType.TIME_DEPTH:
          result = tableData?.['10']?.depth.map((el, index) => {
            return {
              cells: [
                { value: tableRowName(round(el, 0)) },

                {
                  value:
                    selectedDataEntity === 0
                      ? roundToFixed(tableData?.mean?.time?.[index], 2)
                      : displayNumber(
                          round(tableData?.mean?.costs?.[index], 0),
                          { scientific: false },
                        ),
                },

                {
                  value:
                    selectedDataEntity === 0
                      ? roundToFixed(tableData?.['10']?.time?.[index], 2)
                      : displayNumber(
                          round(tableData?.['10']?.costs?.[index], 0),
                          { scientific: false },
                        ),
                },
                {
                  value:
                    selectedDataEntity === 0
                      ? roundToFixed(tableData?.['50']?.time?.[index], 2)
                      : displayNumber(
                          round(tableData?.['50']?.costs?.[index], 0),
                          { scientific: false },
                        ),
                },
                {
                  value:
                    selectedDataEntity === 0
                      ? roundToFixed(tableData?.['90']?.time?.[index], 2)
                      : displayNumber(
                          round(tableData?.['90']?.costs?.[index], 0),
                          { scientific: false },
                        ),
                },
              ],
            };
          });
          break;

        case simulationChartType.ACTIVITY_DURATIONS:
          result = tableData
            ?.map((el) => {
              return el.activity.map((activity) => {
                const cells = [
                  {
                    value: activity.name,
                  },
                  { value: roundToFixed(activity.average, 2) },
                  { value: roundToFixed(activity.percentiles[0], 2) },
                  { value: roundToFixed(activity.percentiles[1], 2) },
                  { value: roundToFixed(activity.percentiles[2], 2) },
                ];
                return { cells };
              });
            })
            .flat();
          break;
        case simulationChartType.COST_ITEM_OVERVIEW:
          tableData?.forEach((item) => {
            createCostRow(item, 0);
          });
          break;
        case simulationChartType.RISKS:
          result = tableData?.map((row) => ({
            cells: [
              {
                value: row.name,
              },
              {
                value: round(row.certainty),
              },
              {
                value: roundToFixed(row.mean, 2),
              },
            ],
          }));
          break;
        case simulationChartType.SECTION_WATERFALL: {
          const { dataType, sections, values, totalSum, threshold } =
            getSimulationDataForWaterfall(
              simulationsResult,
              selectedDataEntity,
              HOURS_PER_DAY,
            );
          const { labels = [] } = sections || {};

          result = [...labels, t(translations.total)]?.map((row, index) => ({
            cells: [
              {
                value: row,
              },
              {
                value:
                  dataType === sectionObjectKeys.DURATIONS
                    ? roundToFixed([...values, totalSum][index], 2)
                    : roundToFixed(
                        normalizeValue([...values, totalSum][index], threshold),
                        2,
                      ),
              },
            ],
          }));
          break;
        }
        default:
          result = [];
          break;
      }
    }
    return result;
  };

  const onClickDownLoad = (exportData, title) => {
    const { headers, rows } = exportData;
    const config = { delimiter: '\t' };
    const text = unParse(headers, getRowsData(rows), config);
    const blob = new Blob([text], { type: 'text/plain' });
    const a = window.document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = `${title}.xls`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const onClickCopyClipboard = (exportData, separator) => {
    const { headers } = exportData;
    let { rows } = exportData;
    rows = getRowsData(rows);
    const config = { delimiter: '\t' };
    if (separator === decimalSeparator.COMMA) {
      rows = rows.map((r) => {
        return r.map((v) => v.toString().replace(/\./g, ','));
      });
    }
    const text = unParse(headers, rows, config);
    try {
      navigator.clipboard.writeText(text).then(() => {
        toast({
          message: {
            type: 'Success',
            content: t(translations.copiedToClipboard),
          },
        });
      });
    } catch (error) {
      toast({
        message: {
          type: 'Error',
          content: t(translations.unableToCopyToClipboard),
          details: error,
        },
      });
    }
  };
  const table = {
    testId: `result-table-${chartKey}`,
    columnAlignment: generateTableAlignment(selectedItem?.value),
    headers: generateTableHeaders(selectedItem?.value),
    rows: generateRows(tableData, selectedItem?.value),
  };

  const exportOptions = [
    {
      type: 'Option',
      label: t(translations.downloadFile),
      onClick: () => onClickDownLoad(table, selectedItem.value),
    },
    {
      type: 'Menu',
      menu: {
        trigger: 'Text',
        label: t(translations.copyToClipboard),
        sections: [
          {
            type: 'Option',
            label: t(translations.copyDecimalDot),
            onClick: () => onClickCopyClipboard(table, decimalSeparator.DOT),
          },
          {
            type: 'Option',
            label: t(translations.copyDecimalComma),
            onClick: () => onClickCopyClipboard(table, decimalSeparator.COMMA),
          },
        ],
      },
    },
  ];

  if (!tableData || (Array.isArray(tableData) && !tableData.length)) {
    return (
      <Flex alignItems="center" justifyContent="center" height="100%">
        <Heading top>There is no data for {selectedItem?.label}</Heading>
      </Flex>
    );
  }
  return (
    <>
      <Portal id={controlsPortalId}>
        <Menu
          menu={{
            component: (
              <Button basic icon={<TbDownload />} colored="muted" round small />
            ),
            trigger: 'Component',
            small: true,
            sections: exportOptions,
          }}
        />
      </Portal>
      <div id={controlsPortalId} />
      <Spacer height="12px" />
      <Table table={table} />
    </>
  );
};

const mapStateToProps = ({ entities }) => {
  const primaryCurrency = selectPrimaryCurrency(entities);
  const {
    activityModel: { operations },
  } = entities;

  return {
    primaryCurrency,
    operations,
  };
};

export default withErrorBoundary(connect(mapStateToProps)(ResultTable));
