import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import {
  Heading,
  Field,
  InputGroup,
  Spacer,
  Divider,
  Text,
} from '@oliasoft-open-source/react-ui-library';
import { getResolver } from '~src/validation/resolver';
import translations from '~src/internationalisation/translation-map.json';
import { settingsCostItemsSchemaValidator } from '~schemas/ajv-validators';
import {
  Input,
  NumberInput,
  Select,
  CheckBoxList,
  UnitInput,
  InputWithVariable,
  SingleCheckBox,
} from '~common/form-inputs';
import { convertToStorageUnits, convertToWithUnits } from '~common/units/units';
import { CostType, QuantityType } from '~src/enums/cost-setup';
import { useAutoSave } from '~src/common/auto-save/use-auto-save';
import { autoSaveWait } from '~src/config/config';
import { initialCostItem } from '~src/store/entities/user-settings/user-settings';
import {
  VolumeLinkFieldsType,
  LengthLinkFieldsType,
} from '~src/enums/settings';

const CostItemForm = ({
  costItem,
  entitySettings,
  updateCostItem,
  currenciesOptions,
  groupedOperations,
}) => {
  const { t } = useTranslation();
  const { company: companyId } = useParams();

  const { settings, selectedId } = entitySettings;
  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: initialCostItem,
    resolver: getResolver(settingsCostItemsSchemaValidator),
  });
  useEffect(() => {
    const costData = costItem || initialCostItem;
    const convertedItem = convertToWithUnits(
      costData,
      ['from', 'to', 'volume'],
      { from: 'm', to: 'm', volume: 'm3' },
      { from: 'm', to: 'm', volume: 'm3' },
      true,
    );
    reset(convertedItem);
  }, [costItem, reset]);
  const debounceAddCostItem = useRef(debounce(updateCostItem, autoSaveWait));
  const onSubmit = handleSubmit((data) => {
    const convertedData = convertToStorageUnits(
      data,
      ['from', 'to', 'volume'],
      { from: 'm', to: 'm', volume: 'm3' },
    );
    debounceAddCostItem.current(convertedData, settings, selectedId, companyId);
  });
  useAutoSave(onSubmit, watch);

  const typeList = [
    { label: t(translations.costSetup_dayRate), value: CostType.DayRate },
    { label: t(translations.costSetup_lumpSum), value: CostType.LumpSum },
    { label: t(translations.costSetup_quantity), value: CostType.Quantity },
  ];

  const quantityList = [
    { label: t(translations.costSetup_length), value: QuantityType.Length },
    { label: t(translations.costSetup_volume), value: QuantityType.Volume },
  ];
  const { type, currency, quantity, isStandalone } = watch();
  const selectedUnit = () => {
    return quantity === QuantityType.Length ? 'm' : 'm3';
  };

  return (
    <>
      <Heading top>{watch('name')}</Heading>
      <form>
        <InputGroup>
          <Field label={t(translations.name)}>
            <Input
              name="name"
              control={control}
              errors={errors}
              width="300px"
            />
          </Field>
          <Spacer width="var(--padding)" />
          <Field label={t(translations.type)}>
            <Select
              name="type"
              control={control}
              errors={errors}
              options={typeList}
              width="125px"
            />
          </Field>
        </InputGroup>
        <Spacer height="var(--padding-xs)" />
        {type !== CostType.Quantity && (
          <Field label={t(translations.cost)}>
            {type !== CostType.Quantity && (
              <InputGroup width="200px">
                <NumberInput
                  name="cost"
                  control={control}
                  errors={errors}
                  width="100px"
                />
                <Select
                  name="currency"
                  control={control}
                  errors={errors}
                  options={currenciesOptions}
                />
              </InputGroup>
            )}
          </Field>
        )}
        {type === CostType.Quantity && (
          <Field>
            <Field label={t(translations.costSetup_quantity)}>
              <Select
                name="quantity"
                control={control}
                errors={errors}
                options={quantityList}
                width="125px"
              />
            </Field>
            {quantity === QuantityType.Length && (
              <Field label={t(translations.costSetup_length)}>
                <InputWithVariable
                  name="length"
                  unitkey={'length'}
                  unit={'m'}
                  errors={errors}
                  control={control}
                  linkFields={LengthLinkFieldsType}
                  width="200px"
                  noConversion
                />
              </Field>
            )}
            {quantity === QuantityType.Volume && (
              <Field label={t(translations.costSetup_volume)}>
                <InputWithVariable
                  name="volume"
                  unitkey={'volume'}
                  unit={'m3'}
                  errors={errors}
                  control={control}
                  linkFields={VolumeLinkFieldsType}
                  width="200px"
                  noConversion
                />
              </Field>
            )}
            <Field label={t(translations.costSetup_unitCost)}>
              <UnitInput
                name="unitCost"
                unit={`${currency}/${selectedUnit()}`}
                control={control}
                errors={errors}
                width="205px"
              />
            </Field>
          </Field>
        )}
        <Divider margin={'var(--padding)'} />
        {type === CostType.LumpSum && (
          <SingleCheckBox
            control={control}
            errors={errors}
            onChange={(e) => {
              const { checked } = e.target;
              setValue('isStandalone', checked);
              if (checked) {
                setValue('operations', []);
              }
            }}
            label={t(translations.costSetup_planningCost)}
            name="isStandalone"
            checked={false}
          />
        )}
        <Spacer height="var(--padding-xs)" />
        <Heading>{t(translations.risks_applyToOperations)}</Heading>
        <Spacer height="var(--padding-xs)" />
        <Text
          link
          onClick={() =>
            setValue(
              'operations',
              groupedOperations
                ?.map((item) =>
                  item.operations.map((operation) => operation.id),
                )
                .flat(),
            )
          }
        >
          {t(translations.checkAll)}
        </Text>
        <Spacer width="var(--padding)" />
        <Text link onClick={() => setValue('operations', [])}>
          {t(translations.uncheckAll)}
        </Text>
        <Spacer height="var(--padding-xs)" />
        {groupedOperations?.map((section) => {
          return (
            <div key={section.linkedSectionId}>
              <Spacer height="var(--padding-xs)" />
              <Text faint>{`${section.sectionName} ${
                section.sectionTypeName ?? ''
              }`}</Text>
              <CheckBoxList
                items={section.operations}
                control={control}
                errors={errors}
                name="operations"
                disabled={isStandalone}
              />
            </div>
          );
        })}
      </form>
    </>
  );
};

const mapStateToProps = ({ entities }) => {
  const {
    userSettings: { currencies },
  } = entities;
  const currenciesOptions = currencies.map((currency) => ({
    label: currency.code,
    value: currency.code,
  }));
  return {
    currenciesOptions,
  };
};

const Container = connect(mapStateToProps)(CostItemForm);

export { Container as CostItemForm };
