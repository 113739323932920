import { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  Page,
  Spacer,
  Loader,
  Spinner,
  Row,
  Column,
} from '@oliasoft-open-source/react-ui-library';
import {
  getCostTrackerItems,
  listCleared,
} from '~store/entities/cost-tracker/cost-tracker';
import {
  getElements as getCostItems,
  costCleanup,
} from '~store/entities/cost-setup/cost-setup';
import { getSimulations } from '~store/entities/simulations/simulations';
import { getSectionsDataStructure } from '~src/store/entities/sections-data-structure/sections-data-structure';
import translations from '~src/internationalisation/translation-map.json';
import { OperationsTable } from '~views/cost-tracker/operations-table';
import { selectIsPageDisabled } from '~src/store/entities/projects/selectors';
import { projectPages } from '~src/enums/projects';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import { selectCostTrackerList } from '~src/store/entities/cost-tracker/selectors';
import { GroupedCostTrackerItem } from '~src/common/interfaces/cost-tracker.interfaces';
import { CostTrackerHeader } from './cost-tracker-header';
import { CostTrackerDrawer } from './drawer/cost-tracker.drawer';
import type { TRootState } from '~src/store/store-types';

 type TCostTrackerProps = {
  itemsList: GroupedCostTrackerItem[],
  isFetching: boolean,
  isPageDisabled: boolean,
} & PropsFromRedux;
const CostTracker = ({
  itemsList,
  isFetching,
  isPageDisabled,
  getCostTrackerItems,
  getSectionsDataStructure,
  getSimulations,
  getCostItems,
  costCleanup,
  listCleared,
}: TCostTrackerProps) => {
  const { t } = useTranslation();
  const { project } = useParams();
  useEffect(() => {
    getCostTrackerItems(project!);
    getCostItems(project);
    getSectionsDataStructure();
    return () => {
      listCleared();
      costCleanup();
    };
  }, []);

  useEffect(() => {
    if (project) {
      getSimulations(project);
    }
  }, [project]);

  const [viewMode, setViewMode] = useState(1);

  const onViewModeChange = (key: number) => {
    setViewMode(key);
  };

  return (
    <Page padding={false} scroll={false}>
      {isFetching && (
        <Loader text={t(translations.fetching)} theme="light" cover>
          <Spinner dark />
        </Loader>
      )}
      <Row spacing={0} height="100%">
        <CostTrackerDrawer />
        <Column padding={true} scroll>
          <CostTrackerHeader
            isPageDisabled={isPageDisabled}
            onViewModeChange={onViewModeChange}
            viewMode={viewMode}
          />
          <Spacer />
          {viewMode ? (
            <OperationsTable
              itemsList={itemsList}
              isPageDisabled={isPageDisabled}
            />
          ) : null}
        </Column>
      </Row>
    </Page>
  );
};

const mapStateToProps = ({ entities }: TRootState) => {
  const { isFetching } = entities.costTracker;

  return {
    itemsList: selectCostTrackerList(entities),
    isFetching,
    // @ts-expect-error - need refactore selector
    isPageDisabled: selectIsPageDisabled(entities, projectPages.TIME_TRACKER),
  };
};

const mapDispatchToProps = {
  getCostTrackerItems,
  getSimulations,
  getSectionsDataStructure,
  listCleared,
  getCostItems,
  costCleanup,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Container = withErrorBoundary(
  connector(CostTracker),
  { isPage: true },
);

export { Container as CostTracker };
