import { Flex, Heading } from '@oliasoft-open-source/react-ui-library';
import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import translations from '~src/internationalisation/translation-map.json';
import { capitalize, sum } from 'lodash';
import {
  displayNumber,
  round,
  roundToFixed,
} from '@oliasoft-open-source/units';
import {
  BarChart,
  LineChart,
  ScatterChart,
} from '@oliasoft-open-source/charts-library';
import { simulationChartType } from '~src/enums/simulations';
import { HOURS_PER_DAY } from '~src/enums/general';
import { selectPrimaryCurrency } from '~store/entities/company-settings/selectors';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import {
  generateHorizontalBartChartViewData,
  generateScatterChartViewData,
  generateVerticalStackedBarChartViewData,
  generateCumulativeChartData,
  shownCumulativeProbabilitiesForChart,
} from '../simulations';
import './style.less';
import {
  getMean,
  normalizeValue,
  getSimulationDataForWaterfall,
  sectionObjectKeys,
} from './result-utils';

const ResultChart = ({
  selectedItem,
  selectedDataEntity,
  simulationsResult,
  primaryCurrency,
  controlsPortalId,
  operations,
  riskFilter,
  sectionItems,
}) => {
  const { t } = useTranslation();
  let content;
  const handleOnHover = (ev, di, i, gd) => {
    const { chart } = ev;
    const { x, y } = gd[di].data[i];
    const value = t(
      selectedDataEntity === 0
        ? `${t(translations.days)}`
        : `${primaryCurrency}`,
    );

    const tooltipText = `P${y}: ${displayNumber(round(x, 2), {
      scientific: false,
    })} ${value}`;
    chart.config.options.plugins.tooltip.callbacks.label = (ctx) => {
      let label = ctx.dataset.label || '';
      if (label) {
        label = tooltipText;
      }
      if (
        di === 0 &&
        i === 0 &&
        selectedDataEntity === 0 &&
        gd?.length > 1 &&
        ctx.dataset.label === t(translations.simulations_cumulativeProbability)
      ) {
        label = '';
      }
      return label;
    };

    chart.config.options.plugins.tooltip.callbacks.title = (ctx) => {
      let label = ctx[0].label || '';
      if (label) {
        label = '';
      }
      return label;
    };
  };

  const chartMessage = (chartName) => {
    return (
      <Flex alignItems="center" justifyContent="center" height="100%">
        <div className="chart-message">
          <Heading top>There is no data for {chartName}</Heading>
        </div>
      </Flex>
    );
  };

  switch (selectedItem?.value) {
    case simulationChartType.CUMULATIVE_PROBABILITY: {
      const probabilities =
        selectedDataEntity === 0
          ? simulationsResult?.cumulative_probability?.probabilities
          : simulationsResult?.cumulative_probability_cost?.probabilities;
      const time = simulationsResult?.cumulative_probability?.time?.map(
        (t) => t / HOURS_PER_DAY,
      );
      const subactivity =
        simulationsResult?.cumulative_subactivity_percentiles?.map(
          (el) => el / HOURS_PER_DAY,
        );
      const cost =
        simulationsResult?.cumulative_probability_cost?.costs ??
        simulationsResult?.cumulative_probability_cost?.cost;
      const chartDataByEntity = selectedDataEntity === 0 ? time : cost;
      const hideLine = (ctx, value) => {
        return ctx.p0.parsed.y === ctx.p1.parsed.y ? value : undefined;
      };

      if (
        probabilities &&
        probabilities.length > 0 &&
        chartDataByEntity &&
        chartDataByEntity.length > 0
      ) {
        const chartData = generateCumulativeChartData(
          chartDataByEntity,
          shownCumulativeProbabilitiesForChart,
        );
        const chartDataSubactivity = generateCumulativeChartData(
          subactivity,
          shownCumulativeProbabilitiesForChart,
        );
        const chartOptions = {
          controlsPortalId,
          options: {
            chartStyling: {
              height: '100%',
            },
            additionalAxesOptions: {
              reverse: true,
            },
            axes: {
              x: [
                {
                  label:
                    selectedDataEntity === 0
                      ? `${t(translations.time)} [${t(translations.days)}]`
                      : `${t(translations.cost)} [${primaryCurrency}]`,
                },
              ],
            },
            scales: {
              y: {
                ticks: {
                  callback: (val) => {
                    if (val >= 1 && val <= 99) {
                      return `P${val}`;
                    }
                    return '';
                  },
                },
              },
              x: {
                ticks: {
                  callback: (val) => {
                    return displayNumber(val, { scientific: false });
                  },
                },
              },
            },
            interactions: {
              onHover: handleOnHover,
            },
            tooltip: {
              scientificNotation: false,
            },
          },
          data: {
            datasets: [
              selectedDataEntity === 0 && chartDataSubactivity?.length > 0
                ? {
                    label: t(translations.simulations_riskOnly),
                    data: chartDataSubactivity,
                    pointBackgroundColor: 'rgba(107, 156, 131)',
                    borderColor: 'rgba(107, 156, 131)',
                    backgroundColor: 'rgba(107, 156, 131, 0.3)',
                    fill: { value: 99 },
                  }
                : null,
              {
                label: t(translations.simulations_cumulativeProbability),
                data:
                  selectedDataEntity === 0 && chartDataSubactivity?.length > 0
                    ? [
                        {
                          x: chartDataSubactivity?.[0].x,
                          y: 0 + 1,
                        },
                        ...chartData,
                      ]
                    : chartData,
                pointBackgroundColor: 'rgba(129, 129, 78)',
                borderColor: 'rgba(129, 129, 78)',
                backgroundColor: 'rgba(129, 129, 78, 0.3)',
                segment: {
                  borderColor: (ctx) => hideLine(ctx, 'rgba(129, 129, 78, 0'),
                },
                ...(selectedDataEntity === 0 && {
                  fill: { value: 99 },
                }),
              },
            ].filter(Boolean),
          },
        };
        content = <LineChart chart={chartOptions} />;
      } else {
        content = chartMessage(selectedItem.label);
      }

      break;
    }

    case simulationChartType.OPERATION_DURATIONS:
      {
        const labels =
          selectedDataEntity === 0
            ? simulationsResult?.operation_durations?.labels
            : simulationsResult?.operation_costs?.labels;
        const values =
          selectedDataEntity === 0
            ? simulationsResult?.operation_durations?.durations
            : simulationsResult?.operation_costs?.costs;
        let chartViewData;
        if (values) {
          const { ...chartData } = values;
          chartViewData = generateHorizontalBartChartViewData(
            chartData,
            selectedDataEntity,
          );
        }

        if (chartViewData && chartViewData.length > 0) {
          const chartData = {
            controlsPortalId,
            options: {
              direction: 'horizontal',
              chartStyling: {
                maintainAspectRatio: false,
                height: '100%',
              },
              legend: {
                position: 'right',
              },
              axes: {
                x: [
                  {
                    label:
                      selectedDataEntity === 0
                        ? `${t(translations.time)} [${t(translations.days)}]`
                        : `${t(translations.cost)} [${primaryCurrency}]`,
                  },
                ],
              },
              tooltip: {
                scientificNotation: false,
              },
            },
            data: {
              labels,
              datasets: chartViewData,
            },
          };

          content = <BarChart chart={chartData} />;
        } else {
          content = chartMessage(selectedItem.label);
        }
      }
      break;
    case simulationChartType.SECTION_DURATIONS:
      {
        const labels = simulationsResult?.section_durations?.labels;
        const values = simulationsResult?.section_durations?.durations;
        let chartViewData;
        if (values) {
          const { ...chartData } = values;
          chartViewData = generateHorizontalBartChartViewData(
            chartData,
            selectedDataEntity,
          );
        }

        if (chartViewData && chartViewData.length > 0) {
          const chartData = {
            controlsPortalId,
            options: {
              direction: 'horizontal',
              chartStyling: {
                maintainAspectRatio: false,
                height: '100%',
              },
              legend: {
                position: 'right',
              },
              axes: {
                x: [
                  {
                    label: `${t(translations.time)} [${t(translations.days)}]`,
                  },
                ],
              },
              tooltip: {
                scientificNotation: false,
              },
            },
            data: {
              labels,
              datasets: chartViewData,
            },
          };
          content = <BarChart chart={chartData} />;
        } else {
          content = chartMessage(selectedItem.label);
        }
      }
      break;

    case simulationChartType.TIME_DEPTH:
      {
        const operationVsDepth =
          selectedDataEntity === 0
            ? simulationsResult?.operation_vs_depth
            : simulationsResult?.operation_cost_vs_depth;
        const chartViewData = generateScatterChartViewData(
          operationVsDepth,
          selectedDataEntity,
        );
        const data = {
          datasets: chartViewData,
        };
        if (chartViewData && chartViewData.length > 0) {
          const chartOptions = {
            controlsPortalId,
            options: {
              chartStyling: {
                height: '100%',
              },
              additionalAxesOptions: {
                reverse: true,
              },
              axes: {
                x: [
                  {
                    label:
                      selectedDataEntity === 0
                        ? `${t(translations.time)} [${t(translations.days)}]`
                        : `${t(translations.cost)} [${primaryCurrency}]`,
                  },
                ],
                y: [
                  {
                    label: t(translations.depth),
                  },
                ],
              },

              scales: {
                x: {
                  ticks: {
                    callback: (val) => {
                      return displayNumber(val, { scientific: false });
                    },
                  },
                },
              },
              tooltip: {
                scientificNotation: false,
              },
            },
            data,
          };

          content = <LineChart chart={chartOptions} />;
        } else {
          content = chartMessage(selectedItem.label);
        }
      }
      break;

    case simulationChartType.COST_ITEM_OVERVIEW:
      {
        const labels = simulationsResult?.costs_per_item?.labels;
        const values = simulationsResult?.costs_per_item?.costs;
        const chartLabels = values
          ? Object.keys(values)?.map((item) => capitalize(item))
          : [];
        let chartViewData;
        if (values) {
          const { ...chartData } = values;
          chartViewData = generateVerticalStackedBarChartViewData(
            chartData,
            labels,
          );
        }

        if (chartViewData && chartViewData.length > 0) {
          const chartData = {
            controlsPortalId,
            options: {
              additionalAxesOptions: {
                stacked: true,
              },
              direction: 'vertical',
              chartStyling: {
                maintainAspectRatio: false,
                height: '100%',
              },
              legend: {
                position: 'right',
              },
              axes: {
                y: [
                  {
                    label: `${t(translations.cost)} [${primaryCurrency}]`,
                  },
                ],
              },
              tooltip: {
                scientificNotation: false,
              },
            },
            data: {
              labels: chartLabels,
              datasets: chartViewData,
            },
          };

          content = <BarChart chart={chartData} />;
        } else {
          content = chartMessage(selectedItem.label);
        }
      }
      break;

    case simulationChartType.RISKS:
      {
        const filterMap = riskFilter.reduce(
          (acc, curr) => ({ ...acc, [curr.value]: curr.selected }),
          {},
        );

        const uniqueSections = sectionItems.map((section) => ({
          projectSectionId: section.projectSectionId,
          name: section.name,
        }));

        const data = uniqueSections.map((section) => {
          const tasksForSection = operations
            .filter(
              (operation) =>
                filterMap[operation.operationId] &&
                operation.projectSectionId === section.projectSectionId,
            )
            .flatMap((operation) =>
              operation.tasks
                .filter((task) => !task.isBranch && task.parentId)
                .map((task) => ({
                  y: task.certainty,
                  x: getMean(task, operation.tasks),
                  label: task.name,
                })),
            );
          const dataset = {
            label: section.name,
            data: tasksForSection,
            pointBackgroundHoverColor: '#22221C',
            pointRadius: 6,
            pointHoverRadius: 8,
          };
          return [dataset, section.name];
        });
        const datasets = data.flatMap((el) => el[0]);
        const labels = data.flatMap((el) => el[1]);

        const chartData = {
          options: {
            tooltip: {
              showLabelsInTooltips: true,
              scientificNotation: false,
            },
            axes: {
              x: [
                {
                  label: t(translations.meanTimeUponImpact),
                },
              ],
              y: [
                {
                  label: t(translations.probability),
                },
              ],
            },
            chartStyling: {
              maintainAspectRatio: false,
              height: '100%',
              gradient: {
                display: true,
                gradientColors: [
                  { offset: 0, color: '#D4F5AD' },
                  { offset: 0.5, color: '#F6EEAE' },
                  { offset: 1, color: '#F6B2AE' },
                ],
              },
            },
          },
          data: { datasets, labels },
        };
        content = <ScatterChart chart={chartData} />;
      }
      break;

    case simulationChartType.MODIFIERS:
      {
        const average = simulationsResult?.average;
        const modifiers = Object.values(
          simulationsResult?.contingency_results || {},
        );

        const beforeValue =
          average - modifiers.reduce((acc, curr) => acc + curr.mean, 0);

        const data = modifiers.reduce(
          (acc, curr, index) => {
            const previousValue = index === 0 ? acc[index] : acc[index][1];
            const newValue = previousValue + curr.mean;
            return [...acc, [previousValue, newValue]];
          },
          [beforeValue],
        );
        data.push(average);

        const backgroundColor = [
          '#C5C5C3',
          ...modifiers.map((mod) => (mod.mean < 0 ? '#A3E3A2' : '#E3A3A2')),
          '#C5C5C3',
        ];

        const preprocessData = (data) =>
          data.map((item) => {
            if (Array.isArray(item)) {
              return item.map((value) => roundToFixed(value, 2));
            }
            return item;
          });

        const datasets = [
          {
            label: 'Dataset 1',
            data: preprocessData(data),
            backgroundColor,
          },
        ];
        const labels = [
          t(translations.simulations_beforeModifiers),
          ...modifiers.map((mod) => mod.name),
          t(translations.simulations_afterModifiers),
        ];

        const chartData = {
          controlsPortalId,
          options: {
            chartStyling: {
              maintainAspectRatio: false,
              height: '100%',
            },
            graph: {
              showDataLabels: true,
            },
            legend: {
              position: 'right',
            },
            axes: {
              y: [
                {
                  label: t(translations.hours),
                },
              ],
            },
            tooltip: {
              scientificNotation: false,
            },
          },
          data: {
            datasets,
            labels,
          },
        };
        content = <BarChart chart={chartData} />;
      }
      break;
    case simulationChartType.SECTION_WATERFALL: {
      const { dataType, sections, values, totalSum, threshold } =
        getSimulationDataForWaterfall(
          simulationsResult,
          selectedDataEntity,
          HOURS_PER_DAY,
        );
      if (totalSum) {
        const cumulativeSums = [];
        values?.forEach((currentMeanValue, index) => {
          const previousSum = cumulativeSums[index - 1]
            ? cumulativeSums[index - 1][1]
            : 0;
          const currentSum = Number(currentMeanValue) + Number(previousSum);
          cumulativeSums[index] = [
            roundToFixed(previousSum, 2),
            roundToFixed(currentSum, 2),
          ];
        });
        const sumOfMeanValues = sum(values);
        const labels = sections?.labels?.concat('Total') || [];
        const updatedMeanValues = [...cumulativeSums, sumOfMeanValues];
        const backgroundColor = new Array(labels.length - 1)
          .fill('lightGray')
          .concat('hsl(61, 33%, 60%)');

        const data =
          selectedDataEntity === sectionObjectKeys.DURATIONS
            ? updatedMeanValues
            : updatedMeanValues.map((item) => {
                if (Array.isArray(item)) {
                  return item.map((val) => normalizeValue(val, threshold));
                } else {
                  return normalizeValue(item, threshold);
                }
              });

        const chartData = {
          data: {
            datasets: [
              {
                data,
                backgroundColor,
                borderColor: 'lightGray',
                label: 'Mean',
                hoverBorderColor: 'lightGray',
              },
            ],
            labels,
          },
          options: {
            chartStyling: {
              maintainAspectRatio: false,
              height: '100%',
            },
            axes: {
              x: [
                {
                  label: t(translations.sections),
                },
              ],
              y: [
                {
                  label:
                    dataType === sectionObjectKeys.DURATIONS
                      ? t(translations.simulations_meanTimeDays)
                      : `${t(translations.simulations_meanCost)} ${
                          threshold
                            ? `[M${primaryCurrency}]`
                            : `[${primaryCurrency}]`
                        }`,
                },
              ],
            },
            graph: {
              showDataLabels: true,
            },
            legend: {
              display: true,
            },
            tooltip: {
              scientificNotation: false,
            },
          },
          controlsPortalId,
        };
        content = <BarChart chart={chartData} />;
      } else {
        content = chartMessage(selectedItem.label);
      }
      break;
    }
    default:
      content = (
        <Heading top>
          {t(
            translations.simulations_noDataToDisplayTryToRefreshPageOrRunSimulationOneMoreTime,
          )}
        </Heading>
      );
      break;
  }

  return content;
};

const mapStateToProps = ({ entities }) => {
  const primaryCurrency = selectPrimaryCurrency(entities);
  const {
    activityModel: { operations },
    activityModel: { sectionItems },
  } = entities;

  return {
    primaryCurrency,
    operations,
    sectionItems,
  };
};

export default withErrorBoundary(connect(mapStateToProps)(ResultChart));
