import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Loader, Spinner } from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import { useTranslation } from 'react-i18next';
import {
  addInitialTemplate,
  showTemplatesFormUpdated,
  duplicateTemplate,
  templateSelected,
  reorderTemplate,
  removeTemplate,
  updateTemplate,
  addTemplateFromUpload,
  getAllRigOperationTemplates,
} from '~store/entities/company-settings/company-settings';
import { Templates } from '~views/settings/templates/templates';
import { settingEntity } from '~src/enums/settings';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const CompanyTemplates = ({
  companySettings,
  addInitialTemplate,
  showTemplatesFormUpdated,
  duplicateTemplate,
  templateSelected,
  reorderTemplate,
  removeTemplate,
  updateTemplate,
  addTemplateFromUpload,
  getAllRigOperationTemplates,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    getAllRigOperationTemplates();
  }, []);

  const { isFetching } = companySettings;
  if (isFetching) {
    return (
      <Loader text={t(translations.fetching)} theme="light" cover>
        <Spinner dark />
      </Loader>
    );
  }
  return (
    <Templates
      entitySettings={companySettings}
      templateActions={{
        addInitialTemplate,
        showTemplatesFormUpdated,
        duplicateTemplate,
        templateSelected,
        reorderTemplate,
        removeTemplate,
        updateTemplate,
        addTemplateFromUpload,
      }}
      entity={settingEntity.COMPANY}
    />
  );
};

const mapStateToProps = ({ entities }) => {
  const { companySettings } = entities;
  return {
    companySettings,
  };
};

const mapDispatchToProps = {
  addInitialTemplate,
  showTemplatesFormUpdated,
  duplicateTemplate,
  templateSelected,
  reorderTemplate,
  removeTemplate,
  updateTemplate,
  addTemplateFromUpload,
  getAllRigOperationTemplates,
};

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(CompanyTemplates),
);

export { Container as CompanyTemplates };
