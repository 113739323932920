import jsPDF from "jspdf";
import { format } from 'date-fns-tz';
import { IProjectsOverview } from "~src/common/interfaces/project";
import forecastLogo from '~src/assets/logo-forecast.png';
import { fontSmallSize, lineInterval, padding } from "./common";


const forecastLogoWidth = 30;

  export const addHeaderFooter = (
    doc: jsPDF,
    projectOverview: IProjectsOverview
  ) => {
    const pdfWidth = doc.internal.pageSize.getWidth();
    const pdfHeight = doc.internal.pageSize.getHeight();
    const forecastLogoProps = doc.getImageProperties(forecastLogo);
    const forecastLogoHeight =
      (forecastLogoProps.height * forecastLogoWidth) / forecastLogoProps.width;
    doc.addImage(
      forecastLogo,
      'PNG',
      pdfWidth - forecastLogoWidth - lineInterval,
      padding,
      forecastLogoWidth,
      forecastLogoHeight,
    );
    doc.setFont('helvetica', 'normal');
    doc.setTextColor('#90908d');
    doc.setFontSize(fontSmallSize);
    doc.text(
      `${projectOverview?.name} \u00B7 ${projectOverview?.design?.name} \u00B7 ${format(
        new Date(),
        'd.LLL-yyyy',
      )}`,
      10,
      forecastLogoHeight / 2 + lineInterval,
    );
    doc.setTextColor('#000');
    doc.setFontSize(fontSmallSize);
    const numberOfPages = doc.getNumberOfPages();
    const { pageNumber } = doc.getCurrentPageInfo();
    doc.text(
      `Page ${pageNumber} / ${numberOfPages}`,
      pdfWidth / 2,
      pdfHeight - padding,
      { align: 'center' },
    );
  };