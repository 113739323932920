import { createSelector } from '@reduxjs/toolkit';
import type { TRootState } from '~src/store/store-types';

const selectCostSetup = ({ entities }: TRootState) => entities.costSetup;

export const selectCostItemList = createSelector(
  [selectCostSetup],
  (costSetup) => costSetup.list,
);
const selectActiveCostItem = createSelector([selectCostItemList], (costList) =>
  costList.find((cost: any) => cost.active),
);

export const selectActiveCostItemQuantity = createSelector(
  [selectActiveCostItem],
  (cost: any) => cost?.quantity,
);
