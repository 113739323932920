import { createSelector } from '@reduxjs/toolkit';
import { ISection, IOperation, IActivity } from '~src/common/interfaces/sections-data-structure.interfaces.ts';

const sectionsDataStructure = (entities: any) => entities.sectionsDataStructure;

export const selectSectionsDataStructure = createSelector(
  [sectionsDataStructure],
  ({ sections, operations, activities, isFetching }) => {
    return { sections, operations, activities, isFetching };
  },
);

export const selectMappedSections = createSelector(
  [selectSectionsDataStructure],
  ({ sections }) => sections.map((section: ISection) => ({ label: section.name, value: section.sectionId }))
);

export const selectMappedOperations = createSelector(
  [selectSectionsDataStructure],
  ({ operations }) => operations.map((operation: IOperation) => ({ label: operation.name, value: operation.sectionsOperationId }))
);

export const selectMappedActivities = createSelector(
  [selectSectionsDataStructure],
  ({ activities }) => activities.map((activity: IActivity) => ({ label: activity.name, value: activity.sectionsOperationActivityId }))
);