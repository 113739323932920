import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '~store/middleware/api/api';
import { CostType, QuantityType } from '~src/enums/cost-setup';
import { toast } from '@oliasoft-open-source/react-ui-library';
import { linkFields } from '~src/enums/settings';
import i18n from 'i18next';
import translations from '~src/internationalisation/translation-map.json';

const slice = createSlice({
  name: 'costSetup',
  initialState: {
    isAdding: false,
    isFetching: false,
    isReordering: false,
    isSchematicEmpty: false,
    isTemplatesEmpty: false,
    showCreateCostSetupModelModal: false,
    list: [],
    currencies: [],
    showImportFromPriceBookModalVisible: false,
  },
  reducers: {
    addCategoryRequested: (costSetup) => {
      costSetup.isAdding = true;
    },
    categoryAdded: (costSetup, action) => {
      costSetup.isAdding = false;
      const existingIndex = costSetup.list.findIndex(
        (item) => item.costCategoryId === action.payload.costCategoryId,
      );

      if (existingIndex === -1) {
        const index = costSetup.list.push(action.payload) - 1;
        costSetup.list = costSetup.list.map((item, i) => {
          item.active = i === index;
          return item;
        });
      } else {
        costSetup.list = costSetup.list.map((item) => {
          item.active = false;
          return item;
        });
        costSetup.list[existingIndex] = {
          ...costSetup.list[existingIndex],
          ...action.payload,
        };
        costSetup.list[existingIndex].active = true;
      }
    },
    addCategoryRequestFailed: (costSetup) => {
      costSetup.isAdding = false;
    },
    categoryRemoved: (costSetup, action) => {
      const { list } = costSetup;
      const removedIds = action.payload.map((item) => item.costCategoryId);

      const updatedList = list.filter(
        (costItem) =>
          !removedIds.some(
            (id) => id === costItem.costCategoryId || id === costItem.parentId,
          ),
      );
      costSetup.list = updatedList;
    },
    updateCategoryRequested: (costSetup, action) => {
      costSetup.isAdding = true;
      const existingIndex = costSetup.list.findIndex(
        (item) => item.costCategoryId === action.payload.costCategoryId,
      );

      if (existingIndex !== -1) {
        costSetup.list = costSetup.list.map((item) => {
          item.active = false;
          return item;
        });
        costSetup.list[existingIndex] = {
          ...costSetup.list[existingIndex],
          ...action.payload,
        };
        costSetup.list[existingIndex].active = true;
      }
    },
    categoryUpdated: (costSetup) => {
      costSetup.isAdding = false;
    },
    categoryUpdateFailed: (costSetup) => {
      costSetup.isAdding = false;
    },
    elementsRequested: (costSetup) => {
      costSetup.isFetching = true;
    },
    elementsFetched: (costSetup, action) => {
      costSetup.isFetching = false;
      costSetup.list = action.payload.list;
      costSetup.currencies = action.payload.currencies;
    },
    elementsRequestFailed: (costSetup) => {
      costSetup.isFetching = false;
    },
    elementSelected: (costSetup, action) => {
      costSetup.list = costSetup.list.map((item, i) => {
        item.active = i === action.payload;
        return item;
      });
    },
    addItemRequested: (costSetup) => {
      costSetup.isAdding = true;
    },
    itemAdded: (costSetup, action) => {
      costSetup.isAdding = false;

      const existingIndex = costSetup.list.findIndex(
        (item) => item.costItemId === action.payload.costItemId,
      );

      if (existingIndex === -1) {
        const index = costSetup.list.push(action.payload) - 1;
        costSetup.list = costSetup.list.map((item, i) => {
          item.active = i === index;
          return item;
        });
      } else {
        costSetup.list = costSetup.list.map((item) => {
          item.active = false;
          return item;
        });
        costSetup.list[existingIndex] = {
          ...costSetup.list[existingIndex],
          ...action.payload,
        };
        costSetup.list[existingIndex].active = true;
      }
    },
    addItemRequestFailed: (costSetup) => {
      costSetup.isAdding = false;
    },
    itemRemoved: (costSetup, action) => {
      const { list } = costSetup;
      const index = list.findIndex(
        (item) => item.costItemId === action.payload.costItemId,
      );
      delete list[index];
      costSetup.list = list.filter((i) => i);
    },
    itemsReorderRequested: (costSetup, action) => {
      costSetup.isReordering = true;
      costSetup.list = action.payload;
    },
    itemsReordered: (costSetup) => {
      costSetup.isReordering = false;
    },
    itemsReorderFailed: (costSetup) => {
      costSetup.isReordering = false;
    },
    showCreateCostSetupModelModalUpdated: (costSetup, action) => {
      costSetup.showCreateCostSetupModelModal = action.payload;
    },
    createCostSetupModelRequested: (costSetup) => {
      costSetup.isFetching = true;
    },
    createCostSetupModelFetched: (costSetup, action) => {
      const { costSetupList, isSchematicEmpty, isTemplatesEmpty } =
        action.payload;
      costSetup.isFetching = false;
      costSetup.isSchematicEmpty = isSchematicEmpty;
      costSetup.isTemplatesEmpty = isTemplatesEmpty;
      costSetup.list = [
        ...costSetupList.costCategories,
        ...costSetupList.costItems,
      ];
      costSetup.showCreateCostSetupModelModal = false;
    },
    createCostSetupModelRequestFailed: (costSetup) => {
      costSetup.isFetching = false;
    },
    clearCreateModelFlags: (costSetup) => {
      costSetup.isSchematicEmpty = false;
      costSetup.isTemplatesEmpty = false;
    },
    costCleanup: (costSetup) => {
      costSetup.list = [];
      costSetup.currencies = [];
    },
    costItemsAdded: (costSetup, action) => {
      costSetup.isAdding = false;
      costSetup.list = [...costSetup.list, ...action.payload];
      costSetup.showImportFromPriceBookModalVisible = false;
    },
    costItemsAddFailed: (costSetup) => {
      costSetup.isAdding = false;
    },
    showImportFromPriceBookModalUpdated: (costSetup, action) => {
      costSetup.showImportFromPriceBookModalVisible = action.payload;
    },
    allRemoved: (costSetup) => {
      costSetup.list = [];
    },
  },
});

export const {
  addCategoryRequested,
  categoryAdded,
  addCategoryRequestFailed,
  categoryRemoved,
  elementsRequested,
  elementsFetched,
  elementsRequestFailed,
  elementSelected,
  addItemRequested,
  itemAdded,
  addItemRequestFailed,
  itemRemoved,
  itemsReorderRequested,
  itemsReordered,
  itemsReorderFailed,
  showCreateCostSetupModelModalUpdated,
  createCostSetupModelRequested,
  createCostSetupModelFetched,
  createCostSetupModelRequestFailed,
  clearCreateModelFlags,
  costCleanup,
  updateCategoryRequested,
  categoryUpdated,
  categoryUpdateFailed,
  costItemsAdded,
  costItemsAddFailed,
  showImportFromPriceBookModalUpdated,
  allRemoved,
} = slice.actions;
export default slice.reducer;

export const initialCostItem = {
  name: '',
  type: CostType.DayRate,
  costItemId: null,
  cost: 0,
  operations: [],
  currency: 'USD',
  quantity: QuantityType.Length,
  length: 0,
  volume: 0,
  itemCount: 0,
  holeVolume: 0,
  unitCost: 0,
  linkedValue: linkFields.CUSTOM,
  isDistributed: false,
  isStandalone: false,
  extraTimeValue: 0,
  extraTimeUnit: 'days',
};

/**
 * Add cost setup category
 *
 * @param data
 */
export const addCategory = (data) =>
  apiCallBegan({
    url: '/api/cost-setup/categories',
    method: 'POST',
    data,
    onStart: addCategoryRequested.type,
    onSuccess: (response) => ({
      type: categoryAdded.type,
      payload: response,
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.failedToAdd),
        },
      });
      return {
        type: addCategoryRequestFailed.type,
      };
    },
  });

/**
 * Makes an API call to update a category.
 *
 * @param {Object} data - The category data to be updated.
 * @returns {void}
 */
export const updateCategory = (data) =>
  apiCallBegan({
    url: '/api/cost-setup/categories',
    method: 'POST',
    data,
    onStart: () => ({
      type: updateCategoryRequested.type,
      payload: data,
    }),
    onSuccess: categoryUpdated.type,
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.failedToUpdate),
        },
      });
      return {
        type: categoryUpdateFailed.type,
      };
    },
  });

export const addInitialCategory = (projectId, name, parentId) =>
  addCategory({ projectId, name, parentId });

/**
 * Get cost elements list
 *
 * @param projectId
 */
export const getElements = (projectId) =>
  apiCallBegan({
    url: `/api/cost-setup/${projectId}`,
    method: 'GET',
    onStart: elementsRequested.type,
    onSuccess: (response) => ({
      type: elementsFetched.type,
      payload: response,
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.costSetup_failedToGetCosts),
        },
      });
      return {
        type: elementsRequestFailed.type,
      };
    },
  });

/**
 * Add cost item
 *
 * @param data
 */
export const addCostItem = (data) =>
  apiCallBegan({
    url: '/api/cost-setup/items',
    method: 'POST',
    data,
    onStart: addItemRequested.type,
    onSuccess: (response) => ({
      type: itemAdded.type,
      payload: response,
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.failedToAdd),
        },
      });
      return {
        type: addItemRequestFailed.type,
      };
    },
  });

export const addInitialCostItem = (
  projectId,
  name,
  parentId = null,
  vendor = null,
) => addCostItem({ ...initialCostItem, projectId, name, parentId, vendor });

/**
 * Reorder cost elements
 *
 * @param orderedElements
 * @param list
 */
export const reorderElements = (orderedElements, list) => {
  const orderedElementsData = orderedElements.map((orderedElement, i) => ({
    id: orderedElement.id,
    parentId: orderedElement.parent || null,
    position: i,
    type: orderedElement.itemType,
  }));
  const costElements = list.map((item) => {
    const reorderedItem = orderedElementsData.find(
      (costElement) =>
        costElement.id === item.costItemId ||
        costElement.id === item.costCategoryId,
    );
    if (reorderedItem) {
      return {
        ...item,
        parentId: reorderedItem.parentId || 0,
        position: reorderedItem.position,
      };
    }
    return item;
  });
  costElements.sort((a, b) => a.position - b.position);

  const data = {
    categories: orderedElementsData.filter((item) => item.type === 'category'),
    costItems: orderedElementsData.filter((item) => item.type === 'costItem'),
  };

  return apiCallBegan({
    url: '/api/cost-setup/reorder',
    method: 'POST',
    data,
    onStart: () => ({
      type: itemsReorderRequested.type,
      payload: costElements,
    }),
    onSuccess: itemsReordered.type,
    onError: itemsReorderFailed.type,
  });
};

/**
 * Remove cost item
 *
 * @param id
 * @param data
 */
export const removeCostItem = (id, data = {}) =>
  apiCallBegan({
    url: `/api/cost-setup/items/${id}`,
    method: 'DELETE',
    data,
    onSuccess: (response) => ({
      type: itemRemoved.type,
      payload: response,
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.failedToRemove),
        },
      });
    },
  });

/**
 * Remove cost category
 *
 * @param id
 * @param data
 */
export const removeCostCategory = (id, data = {}) =>
  apiCallBegan({
    url: `/api/cost-setup/categories/${id}`,
    method: 'DELETE',
    data,
    onSuccess: (response) => ({
      type: categoryRemoved.type,
      payload: response,
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.failedToRemove),
        },
      });
    },
  });

/**
 * Remove all cost setup data for a specific project.
 *
 * @param {string} projectId - The ID of the project to remove cost setup data for.
 */
export const removeAll = (projectId) =>
  apiCallBegan({
    url: `/api/cost-setup/${projectId}`,
    method: 'DELETE',
    onStart: (response) => ({
      type: allRemoved.type,
      payload: response,
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.failedToRemove),
        },
      });
    },
  });

/**
 * Create cost setup model based on well details and template
 *
 * @param {string} projectId
 * @param {string} templateId
 */
export const createCostSetupModel = (projectId, templateId) =>
  apiCallBegan({
    url: `/api/well-details/cost-setup-model/${projectId}/${templateId}`,
    method: 'GET',
    onStart: createCostSetupModelRequested.type,
    onSuccess: createCostSetupModelFetched.type,
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.costSetup_failedToCreateCostSetupModel),
        },
      });
      return {
        type: createCostSetupModelRequestFailed.type,
      };
    },
  });

/**
 * Import bulk cost item
 *
 * @param data
 */
export const importBulkCostItems = (data) =>
  apiCallBegan({
    url: '/api/cost-setup/bulk-items',
    method: 'POST',
    data,
    onStart: addItemRequested.type,
    onSuccess: costItemsAdded.type,
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.failedToImport),
        },
      });
      return {
        type: costItemsAddFailed.type,
      };
    },
  });
