import jsPDF from "jspdf";
import autoTable, { RowInput } from "jspdf-autotable";
import { IProjectsOverview } from "~src/common/interfaces/project";
import { ISummaryData } from "~src/common/interfaces/report";
import { addPageTitle } from "./add-page-title";
import { addHeaderFooter } from "./add-header-footer";

export const addSummaryPage = (
    doc: jsPDF,
    padding: number,
    forecastLogoHeight: number,
    projectOverview: IProjectsOverview,
    summaryData: ISummaryData
  ) => {
    doc.addPage();
    addHeaderFooter(doc, projectOverview);
    addPageTitle(doc, 'Summary', padding, forecastLogoHeight + padding * 2);

    const getValue = (data: string | number, title: string) => data || data === 0
    ? `${data} ${title}` : '';

    const rows = [
      [{ content: 'Estimated time', styles: { fontStyle: 'bold' } }, getValue(summaryData.estimatedTime, 'days')],
      [{ content: 'Risk only', styles: { fontStyle: 'bold' } }, getValue(summaryData.risk, 'days')],
      [{ content: 'Actual time', styles: { fontStyle: 'bold' } }, getValue(summaryData.actualTime, 'days')],
      [{ content: 'Estimated cost', styles: { fontStyle: 'bold' } }, getValue(summaryData.estimatedCost, 'MUSD')],
      [{ content: 'Actual cost', styles: { fontStyle: 'bold' } }, getValue(summaryData.actualCost, 'MUSD')],
      [
        { content: 'Contributors', styles: { fontStyle: 'bold' } },
        summaryData.contributors.join(' \n'),
      ],
    ] as RowInput[];
    autoTable(doc, {
      // head: ['columns'],
      body: rows,
      startY: 35,
      theme: 'plain',
      columnStyles: {
        0: { cellWidth: 45 },
        1: { cellWidth: 'auto' },
      },
      styles: {
        fontSize: 10,
        cellPadding: { top: 2, right: 2, bottom: 2, left: 0 },
        halign: 'left',
      },

      didDrawCell: (data) => {
        const { cell, doc } = data;
        const { x, y, width, height } = cell;
        // Draw bottom border for each cell
        doc.setDrawColor('#90908d');
        doc.setLineWidth(0.2);
        doc.line(x, y + height, x + width, y + height);
      },
      margin: { top: 30 },
      didDrawPage: (data) => {
        if (data.pageNumber > 1) {
          addHeaderFooter(doc, projectOverview);
        }
      },
    });
  };