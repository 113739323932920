import { costTrackerFields } from '~src/enums/cost-tracker';
import { getFields } from './get-ct-fields';

const columnAlignment = {
  [costTrackerFields.NAME]: 'left',
  [costTrackerFields.START]: 'left',
  [costTrackerFields.TIME_AFE]: 'right',
  [costTrackerFields.COST_AFE]: 'right',
  [costTrackerFields.TIME_ACTUAL]: 'right',
  [costTrackerFields.COST_ACTUAL]: 'right',
  [costTrackerFields.VARIANCE]: 'right',
  [costTrackerFields.CUMULATIVE_COST]: 'right',
};

export const getColumnAlignment = (disabledFields: string[]) => {
  const fields = getFields(disabledFields);
  return fields.map((field) => columnAlignment[field]);
};
