import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Dialog,
  Button,
  Table,
  Spacer,
  useKeyboardEvent,
  Empty,
  Flex,
  Card,
  Text,
} from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import { TaskOffsetHeader } from '~src/views/activity-model/tasks/task-offset-header';
import { formatTimeToUI } from '~src/views/time-tracker/utils/date/dateUtils';
import { round } from '@oliasoft-open-source/units';
import { useParams } from 'react-router';
import { routes } from '~routes/routes';
import { generatePath } from '~store/navigation/navigation';
import { updateShowOffsetPreviewModal } from '~src/store/entities/activity-model/activity-model';
import { IOffsetActivities, Task, Operation, Section } from '~src/common/interfaces/activity-model';
import { selectMappedSections, selectMappedOperations, selectMappedActivities } from '~src/store/entities/sections-data-structure/selector';
import { distributionTypes } from '~src/enums/tasks';
import { capitalize } from 'lodash';
import type { TRootState } from '~store/store-types';
import './style.css';

type OnApplyFunction = (projectId: string, task: Task, operation: Operation) => void;
type MappedItems = { label: string, value: string };
type TaskOffsetModalProps = PropsFromRedux & {
  task: Task;
  operation: Operation;
  section: Section;
  offsetActivities: IOffsetActivities;
  projectId: string;
  onApply: OnApplyFunction;
  mappedSections: MappedItems[];
  mappedOperations: MappedItems[];
  mappedActivities: MappedItems[];
};


const TaskOffsetModal = ({
  offsetActivities: { offsetActivities, calculatedTimes },
  task,
  operation,
  section,
  updateShowOffsetPreviewModal,
  mappedActivities,
  mappedOperations,
  projectId,
  mappedSections,
  onApply,
}: TaskOffsetModalProps) => {
  const { t } = useTranslation();
  const { company } = useParams();
  const onClose = () => updateShowOffsetPreviewModal();
  useKeyboardEvent('Escape', onClose);
  const sectionGroupName = mappedSections.find((item: MappedItems) => item.value === section.sectionId)?.label;
  const operationGroupName = mappedOperations.find((item: MappedItems) => item.value === operation.sectionsOperationId)?.label;
  const activityGroupName = mappedActivities.find((item: MappedItems) => item.value === task.sectionsOperationActivityId)?.label;
  const isDrill = activityGroupName === 'Drill';
  const handleOnApply = () => {
    const taskWithFlag = {
      ...task,
      isDrill,
    };
    onApply(projectId, taskWithFlag, operation);
    onClose();
  }
  const rows = offsetActivities?.map((activity: any) => {
    return {
      cells: [
        {
          value: activity.name,
          type: 'Link',
          onClick: () =>
            window.open(
              generatePath(routes.timeTracker.fullPath, {
                company,
                project: activity.projectId,
              }),
              '_blank',
            ),
        },
        {
          value: (
            <>
              {activity.activityName}{" "} <Spacer width="var(--padding-xxs)" />
              <span style={{ color: 'var(--color-primary-450)' }}>
                {activityGroupName}
              </span>
            </>
          )
        },
        {
          value: formatTimeToUI(activity.start),
        },
        {
          value: activity.afe,
        },
        {
          value: activity.actual,
          style: {
            background: 'var(--color-background)',
          },
        },
        {
          value: round(activity.estimate - activity.actual),
        },
        {
          value: activity.plannedDepth,
        },
        {
          value: activity.actualDepth,
        },
      ],
    };
  });
  const table = {
    fixedWidth: '100%',
    headers: [
      {
        cells: [
          {
            value: t(translations.activityModel_offsetWell),
          },
          {
            value: t(translations.timeTracker_activityName),
          },
          {
            value: t(translations.timeTracker_startTime),
          },
          {
            value: t(translations.timeTracker_afe),
          },
          {
            value: t(translations.actual),
          },
          {
            value: t(translations.timeTracker_variance),
          },
          {
            value: t(translations.timeTracker_plannedDepth),
          },
          {
            value: t(translations.timeTracker_actualDepth),
          },
        ],
      },
      {
        cells: [
          {
            value: '',
            colSpan: 2,
          },
          {
            value: '',
          },
          {
            value: 'h',
            style: { color: 'hsl(60, 13%, 44%)', fontWeight: 'normal' },
          },
          {
            value: 'h',
            style: { color: 'hsl(60, 13%, 44%)', fontWeight: 'normal' },
          },
          {
            value: 'h',
            style: { color: 'hsl(60, 13%, 44%)', fontWeight: 'normal' },
          },
          {
            value: 'm',
            style: { color: 'hsl(60, 13%, 44%)', fontWeight: 'normal' },
          },
          {
            value: 'm',
            style: { color: 'hsl(60, 13%, 44%)', fontWeight: 'normal' },
          },
        ],
      },
    ],
    rows,
  };
  const content =
    offsetActivities &&
      offsetActivities.length > 0 &&
      calculatedTimes?.distribution ? (
      <>
        <TaskOffsetHeader
          task={activityGroupName}
          operation={operationGroupName}
          section={sectionGroupName}
        />
        <Spacer />
        <Table table={table} />
        <Spacer />
        <div className="card-container">
          <Card>
            <div className="card-inner">
              <Flex direction="column" alignItems="center">
                <Text bold>{t(translations.activityModel_distribution)}</Text>
                <Spacer height={10} />
                <div className="card-result">{capitalize(calculatedTimes.distribution.type)}</div>
                <Spacer height={10} />
              </Flex>
            </div>
          </Card>
          {calculatedTimes.distribution.type === distributionTypes.PERT && (
            <>
              <Card>
                <div className="card-inner">
                  <Flex direction="column" alignItems="center">
                    <Text bold>{t(translations.minimum)}</Text>
                    <Spacer height={10} />
                    <div className="card-result">{calculatedTimes.distribution.A} h</div>
                    <Spacer height={10} />
                  </Flex>
                </div>
              </Card>
              <Card>
                <div className="card-inner">
                  <Flex direction="column" alignItems="center">
                    <Text bold>{t(translations.mostLikely)}</Text>
                    <Spacer height={10} />
                    <div className="card-result">{calculatedTimes.distribution.B} h</div>
                    <Spacer height={10} />
                  </Flex>
                </div>
              </Card>
              <Card>
                <div className="card-inner">
                  <Flex direction="column" alignItems="center">
                    <Text bold>{t(translations.maximum)}</Text>
                    <Spacer height={10} />
                    <div className="card-result">{calculatedTimes.distribution.C} h</div>
                    <Spacer height={10} />
                  </Flex>
                </div>
              </Card>
            </>
          )}
          {calculatedTimes.distribution.type === distributionTypes.SPIKE && (
            <Card>
              <div className="card-inner">
                <Flex direction="column" alignItems="center">
                  <Text bold>{t(translations.mostLikely)}</Text>
                  <Spacer height={10} />
                  <div className="card-result">{calculatedTimes.distribution.B} h</div>
                  <Spacer height={10} />
                </Flex>
              </div>
            </Card>
          )}
          {calculatedTimes.distribution.type === distributionTypes.UNIFORM && (
            <>  <Card>
              <div className="card-inner">
                <Flex direction="column" alignItems="center">
                  <Text bold>{t(translations.minimum)}</Text>
                  <Spacer height={10} />
                  <div className="card-result">{calculatedTimes.distribution.A} h</div>
                  <Spacer height={10} />
                </Flex>
              </div>
            </Card>
              <Card>
                <div className="card-inner">
                  <Flex direction="column" alignItems="center">
                    <Text bold>{t(translations.maximum)}</Text>
                    <Spacer height={10} />
                    <div className="card-result">{calculatedTimes.distribution.C} h</div>
                    <Spacer height={10} />
                  </Flex>
                </div>
              </Card>
            </>
          )}
        </div>
      </>
    ) : (
      <Flex alignItems="center" justifyContent="center" height="200px">
        <Empty
          height="100%"
          text={t(translations.activityModel_noResultsFound)}
          width="100%"
        ></Empty>
      </Flex>
    );
  const footer = (
    <>
      <Button label={t(translations.apply)} onClick={handleOnApply} colored />
      <Button label={t(translations.cancel)} onClick={onClose} />
    </>
  );
  return (
    <Modal visible centered>
      <Dialog
        dialog={{
          heading: t(translations.activityModel_offsetWellSummary),
          content,
          footer,
          onClose,
        }}
      />
    </Modal>
  );
};
const mapStateToProps = ({ entities }: TRootState) => {
  const {
    activityModel: { offsetActivities },
  } = entities;
  const mappedSections = selectMappedSections(entities);
  const mappedOperations = selectMappedOperations(entities);
  const mappedActivities = selectMappedActivities(entities);
  return { offsetActivities, mappedActivities, mappedSections, mappedOperations };
};

const mapDispatchToProps = { updateShowOffsetPreviewModal };

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Container = withErrorBoundary(connector(TaskOffsetModal), {
  isModal: true,
});

export { Container as TaskOffsetModal };
