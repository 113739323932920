import { useTranslation } from 'react-i18next';
import { Row, Column, Text, Spacer } from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

interface TaskOffsetHeaderProps {
  task: string;
  operation: string;
  section: string;
}

const TaskOffsetHeader = ({
  task,
  operation,
  section,
}: TaskOffsetHeaderProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Row spacing={0} >
        <Text muted>{t(translations.filteringOn)}</Text>
      </Row>
      <Row spacing={0} >
        <Column flex={false}>
          <Row spacing={0}>
            <Text bold>{t(translations.section)}: </Text>
            <Spacer width="var(--padding-xs)" />
            <Text>{section}</Text>
          </Row>
        </Column>
        <Spacer width="var(--padding-sm)" />
        <Column flex={false}>
          <Row spacing={0}>
            <Text bold>{t(translations.operation)}: </Text>
            <Spacer width="var(--padding-xs)" />
            <Text>{operation}</Text>
          </Row>
        </Column>
        <Spacer width="var(--padding-sm)" />
        <Column flex={false}>
          <Row spacing={0}>
            <Text bold>{t(translations.activity)}: </Text>
            <Spacer width="var(--padding-xs)" />
            <Text>{task}</Text>
          </Row>
        </Column>
      </Row>
    </>
  );
};

const Container = withErrorBoundary(TaskOffsetHeader, { isModal: true });

export { Container as TaskOffsetHeader };
