export const CostType = Object.freeze({
  DayRate: 'dayRate',
  LumpSum: 'lumpSum',
  Quantity: 'quantity',
});

export const QuantityType = Object.freeze({
  Length: 'length',
  Volume: 'volume',
  ItemCount: 'itemCount',
  HoleVolume: 'holeVolume',
});

export const TimeType = Object.freeze({
  DAYS: 'days',
  HOURS: 'hours',
});
