import { createSelector } from '@reduxjs/toolkit';
import { pageStatuses } from '~src/enums/projects';
import { selectCompanyList } from '~store/entities/companies/selectors';

const selectProjects = (entities) => entities.projects;

export const selectIsPageDisabled = createSelector(
  [selectProjects, (entities, name) => name],
  (projects, name) =>
    projects.overview?.pages?.[name] === pageStatuses.FINISHED,
);

export const selectProjectOverview = createSelector(
  [selectProjects, selectCompanyList],
  (project, companyList) => {
    const { overview } = project;
    const companyName =
      companyList?.find((company) => company.id === overview.companyId)?.name ||
      '';
    return { ...overview, companyName };
  },
);
