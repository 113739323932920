import React from 'react';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import ResultChart from '~src/views/simulations/result-overview/result-chart';
import ResultTable from '~src/views/simulations/result-overview/result-table';
import '~src/views/simulations/result-overview/style.less';

const CompareResultContent = ({
  simulationsResult,
  chartKey,
  showTable,
  selectOptions,
  selectedItem,
  dataEntity,
}) => {
  const controlsChartPortalId = `result-chart-controls-${chartKey}`;
  const controlsTablePortalId = `result-table-controls-${chartKey}`;

  return (
    <div className="content-container">
      {showTable ? (
        <div id={controlsTablePortalId} />
      ) : (
        <div id={controlsChartPortalId} />
      )}
      <div className="content-inner">
        {showTable ? (
          <div>
            <ResultTable
              chartKey={chartKey}
              simulationsResult={simulationsResult}
              selectedItem={selectOptions[selectedItem]}
              selectedDataEntity={dataEntity}
              controlsPortalId={controlsTablePortalId}
            />
          </div>
        ) : (
          <div>
            <ResultChart
              key={chartKey}
              selectedItem={selectOptions[selectedItem]}
              selectedDataEntity={dataEntity}
              simulationsResult={simulationsResult}
              controlsPortalId={controlsChartPortalId}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default withErrorBoundary(CompareResultContent);
