import { round } from '@oliasoft-open-source/units';
import { createSelector } from '@reduxjs/toolkit';
import type { TRootState } from '~src/store/store-types';

const selectCostTracker = (entities: TRootState["entities"]) => entities.costTracker;

export const selectCostTrackerList = createSelector(
  [selectCostTracker],
  ({ list }) => list,
);

export const selectCostTrackerStart = createSelector(
  [selectCostTracker],
  ({ start }) => start,
);

export const selectCostTrackerFinish = createSelector(
  [selectCostTracker],
  ({ finish }) => finish,
);

export const selectDisabledFields = createSelector(
  [selectCostTracker],
  ({ disabledFields }) => disabledFields,
);

export const selectActualCost = createSelector(
  [selectCostTracker],
  ({ list }) => round(
    list?.map((section) => section.operations).flat()?.reduce((acc: number, operation) => acc + (operation.costActual ?? 0), 0),
    0,
  ),
);
